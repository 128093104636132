import { useLocation, useOutlet, useNavigationType } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useRef, useEffect } from 'react';
import { useTracking } from './hooks/use-tracking';

export const App = () => {
    const location = useLocation();
    const currentOutlet = useOutlet();
    const nodeRef = useRef(null);
    const { trackPage, trackSessionEnd } = useTracking();
    const navigationType = useNavigationType();
    const classNames = {
        appear: 'opacity-0',
        appearActive: 'transition-opacity duration-150 opacity-100',
        enter: 'opacity-0',
        enterActive: 'transition-opacity duration-150 opacity-100',
        exitActive: 'transition-opacity duration-150 opacity-0',
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        if (location.pathname !== '/') {
            trackPage();
        } else if (location.pathname === '/' && navigationType === 'PUSH') {
            // On page load nav type is POP, any other navigation we use in the app is PUSH.
            // This fixes unwanted tracking on page load, but allows for example app reset to be tracked, since we use PUSH for that.
            trackSessionEnd();
        }
    }, [location.pathname, trackPage, trackSessionEnd, navigationType]);

    return (
        <div className="relative h-full">
            <SwitchTransition>
                <CSSTransition key={location.pathname} nodeRef={nodeRef} timeout={150} classNames={classNames} unmountOnExit>
                    {() => (
                        <div ref={nodeRef} className="relative h-full">
                            {currentOutlet}
                        </div>
                    )}
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
};
