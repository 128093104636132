import { FloorSelector, FloorWithLocalizedName } from './floor-selector';
import { ZoomControls } from './zoom-controls';
import { Essential, Floor } from '../../../types/visiomap';
import { TouchEventHandler } from 'react';
import { RecenterControls } from './recenter-controls';
import { UpdateFloorFunction } from '../visiomap';
import { ZoomMax } from '../visiomap';

type Props = {
    onZoomIn: () => void;
    onZoomOut: () => void;
    floors: Floor[];
    selectedFloor?: Floor | null;
    onPickFloor: UpdateFloorFunction;
    visioWebEssential: Essential;
    maxedZooms: ZoomMax;
    onRecenterMap: () => void;
};

export const MapControls = ({
    onZoomIn,
    onZoomOut,
    floors,
    onPickFloor,
    selectedFloor = null,
    visioWebEssential,
    maxedZooms,
    onRecenterMap,
}: Props) => {
    const toggleTouchdown = (element: EventTarget & HTMLButtonElement) => {
        element.classList.contains('touchdown') ? element.classList.remove('touchdown') : element.classList.add('touchdown');
    };

    const onTouchStartHandler: TouchEventHandler<HTMLButtonElement> = event => {
        if (!event?.currentTarget || event.currentTarget.disabled) {
            return;
        }

        toggleTouchdown(event.currentTarget);
    };
    const onTouchEndHandler: TouchEventHandler<HTMLButtonElement> = event => {
        if (!event?.currentTarget || event.currentTarget.disabled) {
            return;
        }

        toggleTouchdown(event.currentTarget);
    };

    const localizedFloors = floors.map(
        floor =>
            ({
                ...floor,
                localizedShortName: visioWebEssential?.venue?.getLocalizedShortName({ id: floor.id || '' }) || floor.levelIndex,
            }) as FloorWithLocalizedName
    );

    return (
        <div className="flex h-full items-center justify-end">
            <div className="pointer-events-auto rounded-full bg-white p-4 shadow-sidebar">
                <ZoomControls
                    onZoomIn={onZoomIn}
                    onZoomOut={onZoomOut}
                    onTouchStart={onTouchStartHandler}
                    onTouchEnd={onTouchEndHandler}
                    maxedZooms={maxedZooms}
                />
                <FloorSelector
                    floors={localizedFloors}
                    selectedFloor={selectedFloor || null}
                    onPickFloor={onPickFloor}
                    onTouchStart={onTouchStartHandler}
                    onTouchEnd={onTouchEndHandler}
                />
                <RecenterControls onRecenter={onRecenterMap} onTouchStart={onTouchStartHandler} onTouchEnd={onTouchEndHandler} />
            </div>
        </div>
    );
};
