import { ClassValue } from 'clsx';
import { Link } from 'react-router-dom';
import { useLocalization } from '../../localization/use-localization';
import { OccasionType } from '../../services/meeting-place/occasions';
import { TagsSmall } from './tags-small';

type Props = {
    event: OccasionType;
    classNames?: ClassValue;
    size?: 'small' | 'large' | 'xs';
};

export const EventSmall = ({ event }: Props) => {
    const { formatDate } = useLocalization();

    const eventDate = () => {
        const formattedStartDate = formatDate(event.startDate);
        const formattedEndDate = formatDate(event.endDate);

        if (formattedStartDate === formattedEndDate) {
            return `${formattedStartDate}`;
        }

        return `${formattedStartDate} - ${formattedEndDate}`;
    };

    return (
        <Link to={`/events/${event.sys.id}`} className="max-h-full rounded" data-tracking="view-event-btn">
            <div className="flex-col items-start justify-between overflow-hidden">
                <div className="flex aspect-square h-[50%] flex-grow-0 items-center justify-center overflow-hidden rounded">
                    <div className="aspect-w-1 aspect-h-1 h-full w-full">
                        {event?.coverImage?.url ? (
                            <img src={event.coverImage.url} className={'h-full w-full rounded object-cover'} />
                        ) : null}
                    </div>
                </div>
                <div className="flex flex-col justify-between px-[5%] pt-2">
                    <div className="flex-[1]">
                        <p className="truncate font-bold text-light">{eventDate()}</p>
                        <h2 className="overflow-hidden truncate text-lg font-bold">{event.name}</h2>
                    </div>
                    <div className="h-5" />
                    <div className="flex-[1]">
                        <TagsSmall tags={event.eventTypesCollection?.items || []} />
                    </div>
                </div>
            </div>
        </Link>
    );
};
