import { gql } from 'graphql-request';
import { BaseFetchType } from './offers';

type Response = {
    meetingPlacePageHomeCollection: {
        items: {
            domain: string;
        }[];
    };
};

export const fetchMeetingPlacePageDomain = async ({ client, tagId }: BaseFetchType) => {
    const document = gql`
        query meetingPlacePageHomeCollection($tagId: [String]) {
            meetingPlacePageHomeCollection(where: { contentfulMetadata: { tags: { id_contains_some: $tagId } } }) {
                items {
                    domain
                }
            }
        }
    `;

    const response = await client.request<Response>({
        document,
        variables: {
            tagId,
        },
    });

    return response.meetingPlacePageHomeCollection.items.length > 0 ? response.meetingPlacePageHomeCollection.items[0].domain : '';
};
