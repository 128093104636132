import { LangType } from '../contexts/meeting-place-provider';
import enFile from './translations/en-XZ.json';
import csFile from './translations/cs-CZ.json';
import esFile from './translations/es-ES.json';
import frFile from './translations/fr-FR.json';
import itFile from './translations/it-IT.json';
import plFile from './translations/pl-PL.json';
import ptFile from './translations/pt-PT.json';
import skFile from './translations/sk-SK.json';
import svFile from './translations/sv-SE.json';
import { replaceNullWithQuotesJson } from '../utils/methods';

type Dictionary = Record<
    LangType,
    {
        alphabet: string[];
        translations: Record<string, string>;
    }
>;

const LATIN = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
];

export const dictionary: Dictionary = {
    'zh-CN': {
        // simplified
        alphabet: [],
        translations: {},
    },
    cs: {
        alphabet: [
            'a',
            'á',
            'b',
            'c',
            'č',
            'd',
            'ď',
            'e',
            'é',
            'ě',
            'f',
            'g',
            'h',
            'ch',
            'i',
            'í',
            'j',
            'k',
            'l',
            'm',
            'n',
            'ň',
            'o',
            'ó',
            'p',
            'q',
            'r',
            'ř',
            's',
            'š',
            't',
            'ť',
            'u',
            'ú',
            'ů',
            'v',
            'w',
            'x',
            'y',
            'ý',
            'z',
            'ž',
        ],
        translations: replaceNullWithQuotesJson(csFile.internal),
    },
    en: {
        alphabet: LATIN,
        translations: replaceNullWithQuotesJson(enFile.internal),
    },
    es: {
        alphabet: [
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'm',
            'n',
            'ñ',
            'o',
            'p',
            'q',
            'r',
            's',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
        ],
        translations: replaceNullWithQuotesJson(esFile.internal),
    },
    fr: {
        alphabet: LATIN,
        translations: replaceNullWithQuotesJson(frFile.internal),
    },
    it: {
        alphabet: LATIN,
        translations: replaceNullWithQuotesJson(itFile.internal),
    },
    pl: {
        alphabet: [
            'a',
            'ą',
            'b',
            'c',
            'ć',
            'd',
            'e',
            'ę',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'ł',
            'm',
            'n',
            'ń',
            'o',
            'ó',
            'p',
            'q',
            'r',
            's',
            'ś',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
            'ź',
            'ż',
        ],
        translations: replaceNullWithQuotesJson(plFile.internal),
    },
    pt: {
        alphabet: LATIN,
        translations: replaceNullWithQuotesJson(ptFile.internal),
    },
    sk: {
        alphabet: [
            'a',
            'á',
            'ä',
            'b',
            'c',
            'č',
            'd',
            'ď',
            'dz',
            'dž',
            'e',
            'é',
            'f',
            'g',
            'h',
            'ch',
            'i',
            'í',
            'j',
            'k',
            'l',
            'ĺ',
            'ľ',
            'm',
            'n',
            'ň',
            'o',
            'ó',
            'ô',
            'p',
            'q',
            'r',
            'ŕ',
            's',
            'š',
            't',
            'ť',
            'u',
            'ú',
            'v',
            'w',
            'x',
            'y',
            'ý',
            'z',
            'ž',
        ],
        translations: replaceNullWithQuotesJson(skFile.internal),
    },
    sv: {
        alphabet: [...LATIN, 'å', 'ä', 'ö'],
        translations: replaceNullWithQuotesJson(svFile.internal),
    },
};
