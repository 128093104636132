import { GridListing } from '../grid-listings/grid-listing';
import { useEvents } from '../../hooks/use-events';

export const EventsListing = () => {
    const { getActiveEvents } = useEvents();
    const activeEvents = getActiveEvents();

    if (!activeEvents.length) {
        return null;
    }

    return (
        <div data-component="events">
            <GridListing items={activeEvents} type="events" />
        </div>
    );
};
