import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { Essential, Floor, InstructionData, MouseUpEvent, Place, Route } from '../../types/visiomap';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import { setPlaceShopNames, setPlaceFoodDrinksNames, hideAllPlaceNames, setServiceNames, replaceServiceNames } from './set-place-names';
import { LeftSidePanel } from '../sidebar-components/left/left-side-panel';
import { RightSidePanel } from '../sidebar-components/right/right-side-panel';
import { MapControls } from './components/map-controls';
import { RightSidePanelKioskSelect } from '../sidebar-components/right/right-side-panel-kiosk-select';
import { useTracking } from '../../hooks/use-tracking';
import { setLanguage } from './set-language';
import { addHereYouArePoi, checkHereYouArePoi, removeHereYouArePoi } from './set-custom-poi';
import { computeRoute, createRoute, moveCameraTo, Preference } from './custom-route';
import { getVisionWebNavInstructions, setVisionWebNavInstructions } from './nav-instructions';
import { resetPlaceColors, setActivePlaceColor } from './set-place-colors';
import { setupKioskPicker } from './pick-kiosk';
import { removeOriginalKioskPoi } from './set-custom-poi';
import { useRecenter } from './use-recenter';

export type NavigationInstructionsType = InstructionData & {
    floor?: Floor;
};

export type ActivePlaceType = {
    floorPlanId: string | null;
    isService: boolean;
    previousFloorPlanId: string | null;
    prevIsService: boolean;
};

export type UpdateFloorFunction = (floor?: Floor | null) => void;

export type ZoomMax = {
    zoomInMaxed: boolean;
    zoomOutMaxed: boolean;
};

type Props = {
    pickKiosk?: boolean;
};

export const VISIO_IMAGE_PATH = 'static/visioweb/';

const MEETING_PLACE_UMEA = 'meetingPlaceUmea';

export const VisioMap = ({ pickKiosk = false }: Props) => {
    const visioMapElementRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const visioWebEssentialRef = useRef<Essential | null>(null);
    const [floors, setFloors] = useState<Floor[]>([]);
    const [selectedFloor, setSelectedFloor] = useState<Floor | null>(null);
    const [zoomRadius, setZoomRadius] = useState(200);
    const [isLeftPanelMinified, setIsLeftPanelMinified] = useState(false);
    const [activePlace, setActivePlace] = useState<ActivePlaceType>({
        floorPlanId: null,
        isService: false,
        previousFloorPlanId: null,
        prevIsService: false,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [navigationInstructions, setNavigationInstructions] = useState<NavigationInstructionsType[]>([]);
    const [activeInstructionIndex, setActiveInstructionIndex] = useState(0);
    const context = useContext(MeetingPlaceContext);
    const { trackNavigation } = useTracking();
    const currentRouteRef = useRef<Route | null>(null);
    const [routePreference, setRoutePreference] = useState<Preference>('shortest');
    const isPlace = useMemo(() => {
        if (!activePlace.floorPlanId) {
            return false;
        }

        return !![...context.foodDrink.items, ...context.shops.items].find(place => {
            if (!place.floorPlanIDs) {
                return false;
            }
            return place.floorPlanIDs!.includes(activePlace.floorPlanId || '');
        });
    }, [activePlace, context]);
    const zoomMaxed = useMemo((): ZoomMax => {
        if (!visioWebEssentialRef?.current || !visioWebEssentialRef.current?._mapviewer?.camera) {
            return {
                zoomInMaxed: false,
                zoomOutMaxed: false,
            };
        }

        return {
            zoomInMaxed: zoomRadius <= visioWebEssentialRef.current._mapviewer?.camera?.minRadius,
            zoomOutMaxed: zoomRadius >= visioWebEssentialRef.current._mapviewer?.camera?.maxRadius,
        };
    }, [zoomRadius]);

    const { setDefaultCameraPosition, setKioskFloor, recenterMap, setCurrentNavCameraPosition } = useRecenter();

    useEffect(() => {
        if (visioWebEssentialRef.current === null) {
            initMap();
        }

        return () => {
            if (pathname !== '/map' && context.kioskId) {
                try {
                    visioWebEssentialRef?.current?._mapviewer?.destroy();
                } catch (error) {
                    console.error('Attempted to destroy map, error: ', error);
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (!visioWebEssentialRef.current!._mapviewer!.camera) {
            return;
        }
        const position = visioWebEssentialRef.current!._mapviewer!.camera!.position;
        visioWebEssentialRef.current!._mapviewer!.multiBuildingView.goTo({
            viewpoint: {
                position: {
                    radius: zoomRadius,
                    x: position.x,
                    y: position.y,
                },
            },
        });
    }, [zoomRadius]);

    /* useEffect(() => {
        try {
            if (!visioWebEssentialRef.current) {
                return;
            }

            // we'd need to check if previous was a service and readd the selected-service POI/icon
            if (activePlace?.prevIsService) {
                resetPoi(visioWebEssentialRef.current, activePlace.previousFloorPlanId || '');
            }

            if (!activePlace.isService) {
                return;
            }

            const showServiceOnMap = async () => {
                if (
                    !visioWebEssentialRef.current ||
                    !visioWebEssentialRef.current.venue ||
                    !visioWebEssentialRef.current._mapviewer ||
                    !visioWebEssentialRef.current._mapviewer.camera
                ) {
                    return;
                }

                const activePlacePOF = visioWebEssentialRef.current._mapviewer.getPOF(activePlace.floorPlanId || '');
                if (!activePlacePOF) {
                    throw new Error('No POF found for place, cannot set service on map. FloorplandId was: ' + activePlace.floorPlanId);
                }
                let floorToUse = '';

                if (activePlacePOF.floor.toLocaleLowerCase() === 'outside') {
                    floorToUse = getGroundFloorId(visioWebEssentialRef.current);
                } else {
                    floorToUse = activePlacePOF?.floor || '';
                }

                visioWebEssentialRef.current._mapviewer.multiBuildingView.goTo({
                    floorID: floorToUse,
                    viewpoint: {
                        position: {
                            radius: 2 * visioWebEssentialRef.current._mapviewer.camera.minRadius,
                            x: activePlacePOF.x || 0,
                            y: activePlacePOF.y || 0,
                        },
                    },
                });

                setPoiAsActiveService(visioWebEssentialRef.current, activePlace.floorPlanId || '');
                setSelectedFloor(floors.find(floor => floor.id === floorToUse) || null);
            };

            showServiceOnMap();
        } catch (error) {
            console.error(error);
        }
    }, [activePlace, context.kioskId, floors]); */

    useEffect(() => {
        const trySetRoute = async () => {
            if (!visioWebEssentialRef.current || !visioMapElementRef.current || !activePlace.floorPlanId || !context.kioskId) {
                return;
            }

            // we'd need to check if previous was NOT a service and remove "Here you are" POI, then do nothing.
            // It resets the map to the initial state so do not confuse this with removing POI after successful route calculation below.
            /* if (activePlace?.isService) {
                removeHereYouArePoi(visioWebEssentialRef.current, context.kioskId);
                return;
            } */

            try {
                let route = null;
                const computedRoute = await computeRoute(
                    visioWebEssentialRef.current,
                    context.kioskId,
                    activePlace.floorPlanId,
                    routePreference,
                    context.lang || 'en'
                );

                if (!computedRoute || computedRoute.data.status !== 200) {
                    return;
                }

                route = createRoute(visioWebEssentialRef.current, computedRoute);

                if (!route) {
                    console.error(
                        `Failed to create route. Route is null/undefined. Tried creating route with computedRoute: `,
                        computedRoute
                    );
                    return;
                } else if (route) {
                    currentRouteRef.current = route;
                    setVisionWebNavInstructions(visioWebEssentialRef.current, computedRoute, route, context.lang || 'en');

                    const routingInstructions = getVisionWebNavInstructions(visioWebEssentialRef.current, floors);
                    updateFloor(routingInstructions[0].floor);
                    setNavigationInstructions(routingInstructions);
                    removeHereYouArePoi(visioWebEssentialRef.current, context.kioskId);
                    const currentNavCameraPosition = moveCameraTo(visioWebEssentialRef.current, computedRoute, context.kioskId);
                    trackNavigation(activePlace?.floorPlanId || '', 0, 'start');
                    setActivePlaceColor(visioWebEssentialRef.current, activePlace.floorPlanId);
                    if (currentNavCameraPosition) {
                        setCurrentNavCameraPosition(currentNavCameraPosition);
                    }
                }
            } catch (e) {
                console.error(`Something went wrong when trying to set route: `, e);
            }
        };

        resetPlaceColors(visioWebEssentialRef?.current, [...context.shops.items, ...context.foodDrink.items]);

        currentRouteRef.current?.remove();
        setNavigationInstructions([]);
        setCurrentNavCameraPosition(null);
        trySetRoute();
    }, [
        activePlace,
        context.kioskId,
        trackNavigation,
        context.shops,
        floors,
        routePreference,
        context.lang,
        context.foodDrink.items,
        setCurrentNavCameraPosition,
    ]);

    useEffect(() => {
        if (activePlace.floorPlanId === null && visioWebEssentialRef.current && context.kioskId) {
            const kioskPOF = visioWebEssentialRef!.current!._mapviewer!.getPOF(context.kioskId || '');
            const kioskPOI = checkHereYouArePoi(visioWebEssentialRef?.current, context.kioskId || '');
            if (kioskPOF && !kioskPOI) {
                addHereYouArePoi(visioWebEssentialRef?.current, context.kioskId, kioskPOF);
            }
            return;
        }
    }, [activePlace, context.kioskId]);

    useEffect(() => {
        if (!visioWebEssentialRef.current) {
            return;
        }

        setLanguage(context.lang || 'en', visioWebEssentialRef.current);
    }, [context.lang]);

    const initMap = async () => {
        try {
            visioWebEssentialRef.current = new window.VisioWebEssential({
                element: visioMapElementRef.current,
                imagePath: VISIO_IMAGE_PATH,
            });

            if (visioWebEssentialRef.current === null) {
                console.error('NO MAP!');
                return;
            }

            visioWebEssentialRef.current!.setParameters({
                parameters: {
                    baseURL: 'https://mapserver.visioglobe.com/',
                    hash: context.mapHash || '',
                    mapview: {
                        config: {
                            cameratype: 'perspective',
                        },
                        colors: {
                            highlight: '#d5cdc1',
                        },
                    },
                    locale: { language: context.lang || 'en' },
                },
            });

            await visioWebEssentialRef.current!.createMapviewer();
            setLanguage(context.lang || 'en', visioWebEssentialRef.current);

            const floorList = visioWebEssentialRef
                .current!.venue!.layout!.buildings[visioWebEssentialRef.current!.venue!.layout!.defaultBuildingIndex].floors?.slice()
                .reverse();

            hideAllPlaceNames(visioWebEssentialRef.current!);
            setPlaceShopNames(visioWebEssentialRef.current!, context.shops.items);
            setPlaceFoodDrinksNames(visioWebEssentialRef.current!, context.foodDrink.items);
            setServiceNames(visioWebEssentialRef.current!, context.services.items);
            setFloors(floorList);
            replaceServiceNames(visioWebEssentialRef.current!);

            visioWebEssentialRef.current!._mapviewer!.camera!.minRadius = visioWebEssentialRef.current!._mapviewer!.camera!.minAltitude;
            // For meeting place Umea, reducing the zoom out view to 60% of the max altitude to make the map appear zoomed in.[BDPS-1660]
            visioWebEssentialRef.current!._mapviewer!.camera!.maxRadius =
                context.mpTagId === MEETING_PLACE_UMEA
                    ? 0.5 * visioWebEssentialRef.current!._mapviewer!.camera!.maxAltitude
                    : visioWebEssentialRef.current!._mapviewer!.camera!.maxAltitude;
            visioWebEssentialRef.current!._mapviewer!.camera!.pitchManipulatorEnabled = false;
            visioWebEssentialRef.current!._mapviewer!.camera!.pitchInertiaEnabled = false;
            visioWebEssentialRef.current!._mapviewer!.camera!.rotationManipulatorEnabled = false;
            visioWebEssentialRef.current!._mapviewer!.camera!.rotationInertiaEnabled = false;
            visioWebEssentialRef.current!._mapviewer!.camera!.zoomManipulatorEnabled = false;
            visioWebEssentialRef.current!._mapviewer!.camera!.zoomInertiaEnabled = false;

            if (pickKiosk) {
                const setKioskHandler = (deviceId: string) => {
                    localStorage?.setItem('kioskDeviceId', deviceId);
                    context.setKioskId(deviceId);
                    navigate('/');
                };
                setupKioskPicker(visioWebEssentialRef.current!, context.kioskId || '', VISIO_IMAGE_PATH, setKioskHandler);
                updateFloor(floorList.find(floor => floor.id === visioWebEssentialRef.current!.venue!.currentFloorID) || null);
            } else {
                // Remove the initial kiosk POI, otherwise it'll interfere with the custom POI
                const kioskPOI = visioWebEssentialRef!.current!._mapviewer!.getPOI(context.kioskId || '');
                removeOriginalKioskPoi(kioskPOI, context.kioskId || '');

                const kioskPOF = visioWebEssentialRef!.current!._mapviewer!.getPOF(context.kioskId || '');

                if (!kioskPOF) {
                    console.error('Could not find kioskPOF');
                }

                if (!kioskPOF || !visioWebEssentialRef?.current || !context?.kioskId) {
                    return;
                }

                // set camera heading according to the kiosk position
                visioWebEssentialRef.current!._mapviewer!.camera!.heading = kioskPOF.headingInDegrees;
                addHereYouArePoi(visioWebEssentialRef?.current, context.kioskId, kioskPOF);
                updateFloor(floorList.find(floor => floor.id === kioskPOF.floor) || null);
                setKioskFloor(floorList.find(floor => floor.id === kioskPOF.floor) || null);
                setDefaultCameraPosition({
                    x: visioWebEssentialRef.current!._mapviewer!.camera!.position.x,
                    y: visioWebEssentialRef.current!._mapviewer!.camera!.position.y,
                    radius: visioWebEssentialRef.current!._mapviewer!.camera!.position.radius,
                });

                // Add listener for when a place is clicked, this can be broken off into a separate function or file
                // Reset some of the original listeners, otherwise things won't behave as expected
                visioWebEssentialRef.current!.onObjectMouseUp = () => {};
                visioWebEssentialRef.current!.off('mouseup');
                visioWebEssentialRef.current!.onObjectMouseOver = () => {};
                visioWebEssentialRef.current!.off('mouseover');
                visioWebEssentialRef.current!.onObjectMouseOut = () => {};
                visioWebEssentialRef.current!.off('mouseout');

                visioWebEssentialRef.current!.on('mouseup', (e: MouseUpEvent) => {
                    // Ensure that the elemt is actually a Place. Some elements some strange object with no apparent ID, services seems to be like this. Which is bad, we'd want it to behave the same
                    if (e?.args?.element?.disabled || (e.args.element?.vg && 'poi' in e.args.element.vg && e.args.element.vg.poi)) {
                        if (e.args.element.proxy && e.args.element.proxy.options && e.args.element.proxy.options('id')) {
                            updateActivePlace(e.args.element.proxy.options('id') || null);
                            return;
                        } else {
                            return false;
                        }
                    }
                    updateActivePlace(e?.args?.element || null);
                });
            }

            const gotoPlace = searchParams.get('selectedplace') || '';
            if (gotoPlace) {
                updateActivePlace(gotoPlace);
            }
        } catch (e) {
            console.error('Could not load map.', e);
        }
    };

    function updateActivePlace(floorPlanIdOrPlace: string | null | Place) {
        let floorPlanId: string | null = null;

        if (typeof floorPlanIdOrPlace === 'string') {
            floorPlanId = floorPlanIdOrPlace;
        } else if (floorPlanIdOrPlace?.vg && 'id' in floorPlanIdOrPlace.vg) {
            floorPlanId = floorPlanIdOrPlace?.vg && 'id' in floorPlanIdOrPlace.vg ? floorPlanIdOrPlace.vg.id : null;
        }

        if (floorPlanId === activePlace.floorPlanId) {
            return;
        }

        const isService =
            floorPlanId !== null && context.services.items.some(service => service.floorPlanIDs?.includes(floorPlanId as string));

        setRoutePreference('shortest');
        setActivePlace(prev => {
            return { floorPlanId, isService, previousFloorPlanId: prev.floorPlanId, prevIsService: prev.isService };
        });
        setSearchParams(`selectedplace=${floorPlanId}`);
    }

    const updateFloor: UpdateFloorFunction = floor => {
        if (!visioWebEssentialRef.current!.venue! || !floor) {
            return;
        }

        visioWebEssentialRef.current!._mapviewer!.multiBuildingView.setCurrentFloor(floor.id);
        setSelectedFloor(floor);
    };

    const changeZoom = (zoomIn = true) => {
        const ZOOM_INCREMENT = 150;

        if (!visioWebEssentialRef.current!._mapviewer!.camera) {
            return;
        }

        const position = visioWebEssentialRef.current!._mapviewer!.camera!.position;
        position!.radius = zoomIn ? position.radius - ZOOM_INCREMENT : position.radius + ZOOM_INCREMENT;
        setZoomRadius(position.radius);
    };

    const goToNavigationInstruction = (instruction: NavigationInstructionsType) => {
        const currentIndex = visioWebEssentialRef.current!.navigation?.currentInstructionIndex || 0;

        if (instruction.instructionIndex > currentIndex) {
            for (let i = instruction.instructionIndex - currentIndex; i > 0; i--) {
                visioWebEssentialRef.current!.navigation?.goToNextInstruction();
            }
        } else {
            for (let i = instruction.instructionIndex - currentIndex; i < 0; i++) {
                visioWebEssentialRef.current!.navigation?.goToPreviousInstruction();
            }
        }

        // always force update the floor because we're doing a hack here to prevent outside being shown
        // after the navigation module is done with loading instructions
        updateFloor(instruction.floor);

        trackNavigation(activePlace?.floorPlanId || '', instruction.instructionIndex, 'change');
    };

    return (
        <div className="relative h-full">
            <LeftSidePanel
                activeFloorPlanId={activePlace.floorPlanId || ''}
                isMinified={isLeftPanelMinified}
                setIsMinified={setIsLeftPanelMinified}
            />
            <div className="grid h-full max-h-screen grid-cols-[auto_22.5rem] xl:grid-cols-[auto_30rem]">
                <div className="relative h-full max-w-[100%] overflow-hidden">
                    {visioMapElementRef.current ? (
                        <div
                            className={clsx(
                                'pointer-events-none absolute left-0 z-10 h-full w-40 items-center justify-start transition-all duration-300 ease-in-out',
                                {
                                    'left-[20rem] xl:left-[27.5rem]': isPlace && !isLeftPanelMinified,
                                }
                            )}
                        >
                            <MapControls
                                onZoomIn={() => changeZoom()}
                                onZoomOut={() => changeZoom(false)}
                                floors={floors}
                                selectedFloor={selectedFloor ?? null}
                                onPickFloor={updateFloor}
                                visioWebEssential={visioWebEssentialRef.current!}
                                maxedZooms={zoomMaxed}
                                onRecenterMap={() => recenterMap(visioWebEssentialRef.current!, updateFloor, setActiveInstructionIndex)}
                            />
                        </div>
                    ) : null}
                    <div id="visiomap-element" className="relative h-full w-full" ref={visioMapElementRef} />
                </div>
                <div className="scrollbar-hide z-50 h-full max-h-full overflow-scroll shadow-sidebar">
                    {pickKiosk ? (
                        <RightSidePanelKioskSelect />
                    ) : (
                        <RightSidePanel
                            goToNavigationInstruction={goToNavigationInstruction}
                            navigationInstructions={navigationInstructions}
                            activeInstructionIndex={activeInstructionIndex}
                            setActiveInstructionIndex={setActiveInstructionIndex}
                            visioWebEssential={visioWebEssentialRef.current}
                            activeFloorPlanId={activePlace.floorPlanId}
                            selectPlace={updateActivePlace}
                            routePreference={routePreference}
                            setRoutePreference={setRoutePreference}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
