import { OfferType } from '../../services/meeting-place/offers';
import { Offer } from '../offers/offer';
import { useLocalization } from '../../localization/use-localization';
import { OccasionType } from '../../services/meeting-place/occasions';
import { Event } from '../mp-events/event';
import { Layout } from '../layout/layout';
import { useContext } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';

type Props = {
    items: OfferType[] | OccasionType[];
    type?: 'offers' | 'events';
};

export const GridListing = ({ type, items = [] }: Props) => {
    const { getTranslation } = useLocalization();
    const { verticalMode } = useContext(MeetingPlaceContext);
    const header = getTranslation(type === 'offers' ? 'Offers' : 'Events');
    const gridItems = [header, ...items];

    const renderItem = (item: OfferType | OccasionType | string, key: number) => {
        if (typeof item === 'string') {
            return null;
        }

        return type === 'offers' ? (
            <Offer offer={item as OfferType} classNames="h-auto max-w-full" size="large" key={key} />
        ) : (
            <Event event={item as OccasionType} classNames="h-auto max-w-full" key={key} />
        );
    };

    return (
        <Layout
            showBackArrow={false}
            showMapLink
            className={{
                'bg-dynamic-stroke': type === 'offers' && !verticalMode,
                'bg-dynamic-stroke-1': type === 'events' && !verticalMode,
            }}
        >
            <div className="mb-3 text-4xl font-semibold">{type === 'offers' ? getTranslation('Offers') : getTranslation('Events')}</div>
            <div className="mt-8 grid grid-cols-4 gap-4">{gridItems.map((item, key) => renderItem(item, key))}</div>
        </Layout>
    );
};
