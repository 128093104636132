import { Essential } from '../../types/visiomap';

export const setupKioskPicker = (
    visiowebEssential: Essential,
    kioskId: string,
    imagePath: string,
    clickHandler: (deviceId: string) => void
) => {
    try {
        const pofs = visiowebEssential._mapviewer!.getPOFs();
        const devices = Object.values(pofs).filter(key => key.id.includes('kiosk'));

        devices.forEach(device => {
            visiowebEssential._mapviewer!.addPOI({
                url: imagePath + 'marker_position.png',
                id: device.id,
                text: device.id,
                color: device.id === kioskId ? '#995577' : '#000000',
                position: { x: device.x, y: device.y },
                overlay: true,
                floor: device.floor,
                scale: 15,
                visible: true,
                clickable: true,
                onObjectMouseUp: () => clickHandler(device.id),
            });
        });
    } catch (e) {
        console.error('Error setting up kiosk picker: ', e);
    }
};
