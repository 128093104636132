export type RecenterProps = {
    className?: string;
    width?: number;
    height?: number;
    offset?: { x: number; y: number };
};

export const Recenter = ({ className, width, height, offset }: RecenterProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 48 48`}
        fill="none"
        className={className}
        width={width || '48'}
        height={height || '48'}
    >
        <path
            x="0"
            y="110"
            d="M47.1292 0.796209C46.7606 0.428957 46.295 0.174706 45.7869 0.0633113C45.2789 -0.0480829 44.7497 -0.0119359 44.2614 0.167439L1.76273 15.6147C1.24452 15.8114 0.798273 16.1613 0.483442 16.6179C0.16861 17.0745 0 17.616 0 18.1708C0 18.7256 0.16861 19.2675 0.483442 19.724C0.798273 20.1806 1.24452 20.5305 1.76273 20.7273L2.77329 18.1845L1.76273 20.6999L20.0077 27.9995L27.2728 46.2355C27.4693 46.7542 27.8189 47.2009 28.275 47.5161C28.7311 47.8312 29.2723 48 29.8265 48C30.3808 48 30.9219 47.8312 31.378 47.5161C31.8342 47.2009 32.1837 46.7542 32.3803 46.2355L47.8394 3.72146C48.02 3.21778 48.049 2.67222 47.9227 2.1522C47.7965 1.63219 47.5206 1.16074 47.1292 0.796209ZM29.6218 37.5959L24.5415 24.9101L24.1046 23.8167L23.0393 23.3792L10.339 18.3213L40.6288 7.38528L29.6218 37.5959Z"
            fill="#767571"
            transform={`translate(${offset?.x || 0} ${offset?.y || 0})`}
        />
    </svg>
);
