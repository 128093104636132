import { useContext } from 'react';
import { MeetingPlaceContext } from '../contexts/meeting-place-provider';

export const useFindPlace = (activeFloorPlanId?: string | null) => {
    const { shops, foodDrink } = useContext(MeetingPlaceContext);

    const places = [...shops.items, ...foodDrink.items];

    if (!activeFloorPlanId || !places?.length) {
        return null;
    }

    const place = places.find(place => {
        if (!place.floorPlanIDs) {
            return false;
        }
        return place.floorPlanIDs!.includes(activeFloorPlanId);
    });

    if (!place) {
        return null;
    }

    return place || null;
};
