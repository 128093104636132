import { GraphQLClient, gql } from 'graphql-request';
import { BaseFetchType } from './offers';
import { LangType } from '../../contexts/meeting-place-provider';

type FetchLibraryMeetingPlaceData = {
    libraryMeetingPlaceCollection: {
        items: {
            sys: {
                id: string;
            };
        }[];
    };
};

export type FetchLocaleData = {
    libraryMeetingPlace: {
        primaryLanguage: {
            languageCode: LangType;
        };
    };
};

export const fetchLibraryLanguageCode = async ({ client, meetingPlaceId }: { client: GraphQLClient; meetingPlaceId: string }) => {
    const document = gql`
        query getLangaugeCode($id: String!) {
            libraryMeetingPlace(id: $id) {
                primaryLanguage {
                    languageCode
                }
            }
        }
    `;

    const response = await client.request<FetchLocaleData>({
        document,
        variables: {
            id: meetingPlaceId,
        },
    });

    return response.libraryMeetingPlace.primaryLanguage.languageCode;
};

export const fetchLibraryMeetingPlaceId = async ({ client, tagId }: Omit<BaseFetchType, 'locale'>) => {
    const document = gql`
        query getMeetingPlaces($tagId: [String]) {
            libraryMeetingPlaceCollection(where: { contentfulMetadata: { tags: { id_contains_all: $tagId } } }) {
                items {
                    sys {
                        id
                    }
                }
            }
        }
    `;

    const response = await client.request<FetchLibraryMeetingPlaceData>({
        document,
        variables: {
            tagId: [tagId],
        },
    });

    const ids = response.libraryMeetingPlaceCollection?.items?.map(item => item?.sys?.id);

    return ids[0];
};
