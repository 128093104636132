export type CheckMarkProps = {
    className?: string;
    width?: number;
    height?: number;
};
export const CheckMark = ({ width, height, className }: CheckMarkProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={width || '48'}
        height={height || '48'}
        viewBox="0 0 48 48"
        fill="none"
    >
        <g id="Checkmark Small">
            <path
                id="Vector"
                d="M22.4082 33.6273C22.145 33.6288 21.8841 33.5784 21.6404 33.4788C21.3967 33.3793 21.1751 33.2327 20.9882 33.0473L12.5882 24.6673C12.2116 24.2907 12 23.7799 12 23.2473C12 22.7147 12.2116 22.2039 12.5882 21.8273C12.9648 21.4507 13.4756 21.2391 14.0082 21.2391C14.5408 21.2391 15.0516 21.4507 15.4282 21.8273L22.2082 28.6073L32.7882 14.7873C33.1094 14.366 33.5847 14.0894 34.1096 14.0182C34.6346 13.9469 35.1663 14.0869 35.5882 14.4073C35.7996 14.5665 35.9774 14.7661 36.1111 14.9945C36.2448 15.2229 36.3319 15.4756 36.3672 15.7379C36.4026 16.0002 36.3855 16.2669 36.317 16.5226C36.2485 16.7783 36.13 17.0178 35.9682 17.2273L23.9682 32.8473C23.7946 33.074 23.5745 33.2609 23.3227 33.3954C23.0709 33.5299 22.7931 33.609 22.5082 33.6273H22.4082Z"
                fill="#474747"
            />
        </g>
    </svg>
);
