import { Essential, POF, POI } from '../../types/visiomap';
import { VISIO_IMAGE_PATH } from './visiomap';
import serviceSelectedIcon from '/static/img/service-map-selected-icon.png?url';
// import serviceSelectedIcon from '/static/img/selectedservice.png?url';

const CUSTOM_POI_PREFIX = 'custom_';

/**
 * This is for adding the "Here you are" marker
 * @param visiowebEssential
 * @param IMAGE_PATH
 * @param kioskId
 * @returns
 */
export const addHereYouArePoi = (visiowebEssential: Essential, kioskId: string, kioskPOF: POF) => {
    visiowebEssential?._mapviewer?.addPOI({
        url: `${VISIO_IMAGE_PATH}marker_position.png`,
        id: CUSTOM_POI_PREFIX + kioskId || '',
        position: { x: kioskPOF!.x, y: kioskPOF!.y },
        alignment: { x: 0, y: 1 },
        overlay: true,
        floor: kioskPOF.floor,
        keepAspectRatio: true,
        // fixed: true,
        scale: { x: 10, y: 10, z: 10 },
        zoomScaleFactor: 3,
        zoomScaleVisible: 2,
        zoomScaleFast: 2,
        visible: true,
        clickable: false,
    });
};

/**
 * This is for removing the "Here you are" marker
 * @param visiowebEssential
 * @param kioskId
 */
export const removeHereYouArePoi = (visiowebEssential: Essential, kioskId: string) => {
    const kioskPOI = visiowebEssential._mapviewer!.getPOI(CUSTOM_POI_PREFIX + kioskId || '');

    kioskPOI?.forEach(poi => {
        if (poi?.vg) {
            poi.remove();
        }
    });
};

export const checkHereYouArePoi = (visiowebEssential: Essential, kioskId: string) => {
    const kioskPOI = visiowebEssential._mapviewer!.getPOI(CUSTOM_POI_PREFIX + kioskId || '');

    kioskPOI?.forEach(poi => {
        if (poi?.vg) {
            return true;
        }
    });

    return false;
};

export const setPoiAsActiveService = (visiowebEssential: Essential, floorPlanID: string) => {
    const poi = visiowebEssential!._mapviewer!.getPOI(floorPlanID);

    poi?.forEach(p => {
        if (p.vg) {
            p.twoFour_default_options = p.options();
            p.options('url', serviceSelectedIcon);
            p.options('position', { ...p.options('position') });
            p.options('overlay', true);
            p.options('scale', { x: 10, y: 10, z: 10 });
        }
    });
};

export const resetPoi = (visiowebEssential: Essential, floorPlanID: string) => {
    const servicePOI = visiowebEssential._mapviewer!.getPOI(floorPlanID || '');

    servicePOI?.forEach(poi => {
        if (poi?.vg) {
            poi.options({ ...poi.twoFour_default_options });
        }
    });
};

export const removeOriginalKioskPoi = (kioskPOI: POI[] | undefined, kioskID: string) => {
    if (!kioskPOI) {
        return;
    }

    kioskPOI.forEach(poi => {
        if (poi.options('id') === kioskID) {
            poi.remove();
        }
    });
};
