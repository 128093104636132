import { LangType } from '../../contexts/meeting-place-provider';
import { Essential, Route, ComputedRoute, Floor } from '../../types/visiomap';
import { NavigationInstructionsType, VISIO_IMAGE_PATH } from './visiomap';
import { navigationTranslator } from '../../localization/navigation-translator';

export const setVisionWebNavInstructions = async (
    visiowebEssential: Essential,
    computedRoute: ComputedRoute,
    route: Route,
    language: LangType
) => {
    if (
        !visiowebEssential._mapviewer ||
        !visiowebEssential.navigation ||
        !visiowebEssential._parameters ||
        !visiowebEssential.content.places
    ) {
        //handle error
        return;
    }

    visiowebEssential!._mapviewer!.setupNavigationTranslator(visiowebEssential.content.places);
    visiowebEssential!.navigation!.translator = visiowebEssential!._mapviewer!.navigationTranslator;

    const translation = navigationTranslator(language);
    visiowebEssential!.navigation!.translator.addOrUpdateLanguage(language || '', JSON.stringify(translation));
    visiowebEssential!.navigation!.setLanguageCode({ languageCode: language });

    const allFloors = visiowebEssential!._mapviewer!.getExtraData().resources[language].localized.locale[language].venueLayout;
    visiowebEssential!.navigation.translator.setFloors(allFloors);

    visiowebEssential!.navigation!.languageCode = window.visioweb.Utils.getLanguageMatch(
        visiowebEssential!._parameters!.locale.language,
        visiowebEssential!.navigation!.translator.getLanguageCodes()
    );

    visiowebEssential!.navigation!._currentNavigation = route.navigation;
    visiowebEssential!.navigation!.translator.translateInstructions(computedRoute.data.navigation, language);

    visiowebEssential!.navigation!.currentInstructionIndex = 0;
};

export const getVisionWebNavInstructions = (visiowebEssential: Essential, floors: Floor[]) => {
    if (!visiowebEssential.navigation) {
        return [];
    }

    const instructions: NavigationInstructionsType[] = [];

    for (let i = 0; i < visiowebEssential!.navigation!.nbInstructions; i++) {
        const instructionData = visiowebEssential!.navigation!._currentNavigation.getInstructionData(i);

        instructions.push({
            ...instructionData,
            instructionIndex: i,
            icon: `${VISIO_IMAGE_PATH}${instructionData.icon}`,
            floor: floors.find(floor => floor.id === instructionData.dataset),
        });
    }

    return instructions;
};
