export const ChevronRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 37" fill="none">
        <g id="Chevron Right">
            <path
                id="Chevron Right_2"
                d="M28.0666 18.4462C28.0677 18.6444 28.0299 18.8407 27.9554 19.0241C27.8808 19.2075 27.7709 19.3744 27.6321 19.515L15.6492 31.5574C15.5149 31.7149 15.3498 31.8429 15.1639 31.9333C14.9781 32.0236 14.7756 32.0744 14.5693 32.0824C14.363 32.0904 14.1574 32.0555 13.9652 31.9798C13.773 31.9041 13.5984 31.7893 13.4524 31.6426C13.3065 31.4959 13.1922 31.3205 13.1169 31.1273C13.0416 30.9342 13.0068 30.7274 13.0148 30.5201C13.0228 30.3128 13.0734 30.1094 13.1633 29.9227C13.2532 29.7359 13.3805 29.5698 13.5373 29.4349L24.4567 18.4462L13.5373 7.47271C13.2919 7.18475 13.1637 6.81433 13.1783 6.43548C13.1928 6.05664 13.349 5.69725 13.6158 5.42917C13.8826 5.16108 14.2402 5.00404 14.6171 4.9894C14.9941 4.97477 15.3627 5.10364 15.6492 5.35025L27.6321 17.3926C27.9088 17.6729 28.0649 18.0514 28.0666 18.4462Z"
                // fill="#0058A3"
                fill="#474747"
            />
        </g>
    </svg>
);
