export const A11y = () => (
    <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="64" cy="64" r="64" fill="#f7f4ef" />
        <g clipPath="url(#clip0_429_19232)">
            <path
                d="M83.8356 63.1614C82.1171 59.2402 79.3313 55.912 75.8134 53.5771C72.2954 51.2422 68.195 50 64.0054 50C59.8158 50 55.7154 51.2422 52.1974 53.5771C48.6794 55.912 45.8937 59.2402 44.1752 63.1614C44.0597 63.4251 44 63.7109 44 64C44 64.2891 44.0597 64.5749 44.1752 64.8386C45.8937 68.7598 48.6794 72.088 52.1974 74.4229C55.7154 76.7578 59.8158 78 64.0054 78C68.195 78 72.2954 76.7578 75.8134 74.4229C79.3313 72.088 82.1171 68.7598 83.8356 64.8386C83.9441 64.573 84 64.288 84 64C84 63.712 83.9441 63.427 83.8356 63.1614ZM63.9057 73.9196C60.6379 73.9146 57.4345 72.9863 54.6467 71.2365C51.859 69.4868 49.5944 66.9831 48.1014 64C49.5884 61.0141 51.8501 58.5077 54.6372 56.7574C57.4243 55.0071 60.6283 54.0808 63.8958 54.0808C67.1633 54.0808 70.3673 55.0071 73.1544 56.7574C75.9414 58.5077 78.2032 61.0141 79.6902 64C78.21 66.9676 75.965 69.4616 73.1994 71.2107C70.4339 72.9597 67.2538 73.8967 64.0054 73.9196H63.9057Z"
                fill="#474747"
            />
            <path
                d="M64.18 56C62.5622 56 60.9806 56.4797 59.6354 57.3786C58.2902 58.2774 57.2418 59.555 56.6227 61.0497C56.0035 62.5444 55.8416 64.1891 56.1572 65.7758C56.4728 67.3626 57.2519 68.8201 58.3959 69.9641C59.5399 71.1081 60.9974 71.8872 62.5842 72.2028C64.1709 72.5184 65.8157 72.3565 67.3104 71.7373C68.8051 71.1182 70.0826 70.0698 70.9814 68.7246C71.8803 67.3794 72.36 65.7978 72.36 64.18C72.3547 62.0121 71.4912 59.9346 69.9583 58.4017C68.4254 56.8688 66.3479 56.0053 64.18 56ZM64.18 68.36C63.3533 68.36 62.5451 68.1149 61.8577 67.6555C61.1703 67.1962 60.6346 66.5434 60.3182 65.7796C60.0018 65.0158 59.919 64.1754 60.0803 63.3645C60.2416 62.5537 60.6397 61.8089 61.2243 61.2243C61.8089 60.6397 62.5537 60.2416 63.3645 60.0803C64.1754 59.919 65.0158 60.0018 65.7796 60.3182C66.5434 60.6346 67.1962 61.1703 67.6555 61.8577C68.1149 62.5451 68.36 63.3533 68.36 64.18C68.3547 65.287 67.9127 66.3471 67.1299 67.1299C66.3471 67.9127 65.287 68.3547 64.18 68.36Z"
                fill="#474747"
            />
        </g>
        <defs>
            <clipPath id="clip0_429_19232">
                <rect width="40" height="28" fill="white" transform="translate(44 50)" />
            </clipPath>
        </defs>
    </svg>
);
