import { NavLink } from 'react-router-dom';
import MapView from '../../assets/svg/map-view.tsx';
import { InfoPictogram } from '../../assets/svg/info-pictogram';
import { ArrowRight } from '../../assets/svg/arrow-right';
import { useLocalization } from '../../localization/use-localization';
import { useTracking } from '../../hooks/use-tracking';
import { useContext } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';

const MapCTASection = (props: { isPolygon: boolean | null | undefined }) => {
    const { getTranslation } = useLocalization();
    const { trackSessionStart } = useTracking();
    const { kioskId } = useContext(MeetingPlaceContext);
    const { isPolygon } = props;

    return (
        <div className="relative h-full">
            <NavLink
                to={kioskId ? '/map' : '/select-kiosk'}
                onClick={() => {
                    if (kioskId) {
                        trackSessionStart();
                    }
                }}
                data-tracking="open-map-btn"
            >
                <div className="absolute bottom-0 right-0 h-full">
                    <MapView />
                </div>

                <div
                    className="absolute left-0 top-0 z-50 h-full w-full rounded bg-[#FFDC00]"
                    style={{
                        clipPath: isPolygon
                            ? 'polygon(0% 0%, 100% 0%, 100% 30%, 40% 100%, 0% 100%)'
                            : 'polygon(0% 0%, 100% 0%, 100% 30%, 0% 100%)',
                    }}
                >
                    <div className=" flex max-h-[50%] w-full flex-row px-20 pt-20">
                        <div className="flex-[2]">
                            <h1 className="leading-1 text-8xl font-bold ">{getTranslation('LookingForSomethingSpecial')}</h1>
                        </div>
                        <div className="flex w-full flex-[1] content-normal items-start justify-end">
                            <InfoPictogram />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="absolute bottom-12 right-12 flex items-center gap-2 rounded-[140px] bg-[#FFDC00] px-12 py-5 font-sans text-5xl shadow-map-button">
                        {getTranslation('Map')}
                        <div className="w-16">
                            <ArrowRight />
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
};

export default MapCTASection;
