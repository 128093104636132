import { gql } from 'graphql-request';
import { BaseFetchType } from './offers';
import { Document } from '@contentful/rich-text-types';

type fetchServicesData = {
    meetingPlacePageServiceCollection: {
        items: ServiceType[];
    };
};

export type ServiceType = {
    floorPlanIDs: string[];
    name: string;
    contactDetails: {
        json: Document | null;
    };
    description: {
        json: Document | null;
    };
    serviceType: {
        name: string;
        serviceCategory: {
            name: string;
        };
        coverImage: {
            url: string;
        };
        floorPlanIcon: {
            url: string;
            fileName: string;
            size: number;
        };
    };
};

export const fetchMeetingPlacePageServices = async ({ client, locale, tagId }: BaseFetchType) => {
    const document = gql`
        query getServices($locale: String, $tagId: [String]) {
            meetingPlacePageServiceCollection(locale: $locale, where: { contentfulMetadata: { tags: { id_contains_some: $tagId } } }) {
                items {
                    floorPlanIDs
                    name
                    contactDetails {
                        json
                    }
                    description {
                        json
                    }
                    serviceType {
                        name
                        serviceCategory {
                            name
                        }
                        coverImage {
                            url
                        }
                        floorPlanIcon {
                            url
                            fileName
                            size
                        }
                    }
                }
            }
        }
    `;

    const response = await client.request<fetchServicesData>({
        document,
        variables: {
            locale,
            tagId: [tagId],
        },
    });

    return response.meetingPlacePageServiceCollection.items || [];
};
