import React, { useEffect } from 'react';
import clsx from 'clsx';
import { PlacePreview } from './place-preview';
import { Offers } from './offers';
import { useFindPlace } from '../../../hooks/use-find-place';
import { Close } from '../../../assets/svg/close';
import { Information } from '../../../assets/svg/information';

type Props = {
    activeFloorPlanId: string | null;
    isMinified: boolean;
    setIsMinified: (value: boolean) => void;
};

export const LeftSidePanel: React.FC<Props> = ({ activeFloorPlanId, isMinified, setIsMinified }) => {
    const place = useFindPlace(activeFloorPlanId);

    useEffect(() => {
        setIsMinified(false);
    }, [activeFloorPlanId, setIsMinified]);

    return (
        <div
            className={clsx('absolute inset-y-0 left-0 z-20 h-full bg-primary transition-all duration-300 ease-in-out', {
                'w-[22.5rem] px-8 pt-2 opacity-100 shadow-sidebar xl:w-[30rem]': place && !isMinified,
                'w-0 shadow-sidebar': place && isMinified,
                'w-0 opacity-0': !place,
            })}
        >
            {place ? (
                <button
                    onClick={() => setIsMinified(!isMinified)}
                    className={clsx(
                        'absolute top-8 grid h-16 w-16 place-items-center rounded-r-full bg-white shadow-[10px_0_10px_0_rgba(118,117,113,0.4)] transition-all duration-300 ease-in-out',
                        {
                            'left-[22.5rem] xl:left-[30rem]': !isMinified,
                            'left-0': isMinified,
                        }
                    )}
                    data-tracking="collapse-left-panel-btn"
                >
                    <span
                        className={clsx('block', {
                            'h-16 w-16': !isMinified,
                            'h-10 w-10': isMinified,
                        })}
                    >
                        {isMinified ? <Information /> : <Close fill="none" />}
                    </span>
                </button>
            ) : null}
            <div className="scrollbar-hide h-full space-y-6 overflow-scroll py-6">
                <PlacePreview activeFloorPlanId={activeFloorPlanId || ''} />
                <Offers activeFloorPlanId={activeFloorPlanId || ''} />
            </div>
        </div>
    );
};
