import { GridListing } from '../grid-listings/grid-listing';
import { useOffers } from '../../hooks/use-offers';

export const OffersListing = () => {
    const { getActiveOffers } = useOffers();
    const activeOffers = getActiveOffers();

    if (!activeOffers.length) {
        return null;
    }

    return (
        <div data-component="offers">
            <GridListing items={activeOffers} type="offers" />
        </div>
    );
};
