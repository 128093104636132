import React, { TouchEventHandler } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { ZoomMax } from '../visiomap';

type Props = {
    onZoomIn: () => void;
    onZoomOut: () => void;
    onTouchStart: TouchEventHandler<HTMLButtonElement>;
    onTouchEnd: TouchEventHandler<HTMLButtonElement>;
    maxedZooms: ZoomMax;
};

export const ZoomControls: React.FC<Props> = ({ onZoomIn, onZoomOut, onTouchEnd, onTouchStart, maxedZooms }) => (
    <div className="space-y-3">
        <button
            className="rounded-button"
            onClick={onZoomIn}
            onTouchEnd={onTouchEnd}
            onTouchStart={onTouchStart}
            disabled={maxedZooms.zoomInMaxed}
        >
            <FaPlus />
        </button>
        <button
            className="rounded-button"
            onClick={onZoomOut}
            onTouchEnd={onTouchEnd}
            onTouchStart={onTouchStart}
            disabled={maxedZooms.zoomOutMaxed}
        >
            <FaMinus />
        </button>
    </div>
);
