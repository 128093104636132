import { useCallback, useContext } from 'react';
import { format } from 'date-fns';
import { cs, es, fr, it, pl, pt, sk, sv } from 'date-fns/locale';
import { MeetingPlaceContext } from '../contexts/meeting-place-provider';
import { dictionary } from './dictionary';

export function useLocalization() {
    const { locale, lang } = useContext(MeetingPlaceContext);

    function getLanguageName(langCode: string) {
        switch (langCode) {
            case 'cs':
                return 'Češka';
            case 'es':
                return 'Español';
            case 'fr':
                return 'Français';
            case 'it':
                return 'Italiano';
            case 'pl':
                return 'Polski';
            case 'pt':
                return 'Português';
            case 'sk':
                return 'Slovenský';
            case 'sv':
                return 'Svenska';
            case 'zh-CN':
                return '中文';
        }

        return 'English';
    }

    const getCustomCountryCode = useCallback((langCode: string) => {
        switch (langCode) {
            case 'cs':
                return 'CZ';
            case 'es':
                return 'ES';
            case 'fr':
                return 'FR';
            case 'it':
                return 'IT';
            case 'pl':
                return 'PL';
            case 'pt':
                return 'PT';
            case 'sk':
                return 'SK';
            case 'sv':
                return 'SE';
            case 'zh-CN':
                return 'CN';
        }

        return null;
    }, []);

    function getDateFnsLocale() {
        switch (lang) {
            case 'cs':
                return cs;
            case 'es':
                return es;
            case 'fr':
                return fr;
            case 'it':
                return it;
            case 'pl':
                return pl;
            case 'pt':
                return pt;
            case 'sk':
                return sk;
            case 'sv':
                return sv;
        }

        return null;
    }

    function getTranslation(phrase: string) {
        if (!lang) {
            return 'No language found';
        }

        const language = dictionary[lang];
        if (!language) {
            return `No translation for language: ${lang}`;
        }

        if (phrase === 'A-Z') {
            const alphabet = getAlphabet();
            return `${alphabet[0].toLocaleUpperCase()} - ${alphabet[alphabet.length - 1].toLocaleUpperCase()}`;
        }

        return language.translations[phrase] || `No translation for phrase: ${phrase}`;
    }

    function getAlphabet() {
        // return copy of alphabet array, to avoid mutating the original
        return dictionary[locale || 'en'].alphabet.slice();
    }

    /**
     * Dates we get from the API seem to always be in UTC+0, so we can safely ignore time zones for now.
     * This might be an error from the CMS since normally you'd expect the correct time zone to be included.
     * @param date
     * @param tokens
     * @returns
     */
    function formatDate(date?: string, tokens = 'do MMM yyyy') {
        if (!date) {
            return '';
        }
        const dateOnly = date.split('T')[0]; // Keep only the date part, we'd like to avoid time zone conversions for now.
        const dateFnsLocale = getDateFnsLocale();
        const args = dateFnsLocale ? { locale: dateFnsLocale } : {};

        return format(dateOnly, tokens, args);
    }

    return {
        locale,
        formatDate,
        getAlphabet,
        getLanguageName,
        getTranslation,
        getCustomCountryCode,
    };
}
