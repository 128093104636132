import React from 'react';
import { useLocalization } from '../../../localization/use-localization';
import { ShopType } from '../../../services/meeting-place/shops';

type OpeningHoursProps = {
    activeShop: ShopType;
    parentStyling?: string;
};

type OpeningHoursType = ShopType['openingHours'];

export const OpeningHours: React.FC<OpeningHoursProps> = ({ activeShop, parentStyling }) => {
    const { getTranslation } = useLocalization();
    if (!activeShop.openingHours) {
        return null;
    }

    const mondayOpen = activeShop.openingHours.mondayOpen;
    const mondayClose = activeShop.openingHours.mondayClose;
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const monToFri = weekdays.slice(0, 4);

    // Helper function to check if the shop is closed on a specific day
    const isShopClosed = (activeShop: { openingHours: OpeningHoursType }, day: string) =>
        !/^\d{2}:\d{2}$/.test(activeShop.openingHours![`${day.toLowerCase()}Open` as keyof OpeningHoursType]) ||
        !/^\d{2}:\d{2}$/.test(activeShop.openingHours![`${day.toLowerCase()}Close` as keyof OpeningHoursType]);

    const getDayValue = (day: string, open = true) => `${day.toLowerCase()}${open ? 'Open' : 'Close'}` as keyof OpeningHoursType;

    // Check if opening and closing times are the same for Monday to Friday
    const sameOpeningTimes = monToFri.every(day => activeShop.openingHours![getDayValue(day)] === mondayOpen);
    const sameClosingTimes = monToFri.every(day => activeShop.openingHours![getDayValue(day, false)] === mondayClose);

    const printAndTranslateClosed = (value: string) => {
        const dayValue = activeShop.openingHours![getDayValue(value)];
        return dayValue ? getTranslation(dayValue) : '--';
    };

    if (sameOpeningTimes && sameClosingTimes) {
        return (
            <div className={parentStyling || ''}>
                <h2 className="text-xl font-semibold">{getTranslation('Opening hours')}</h2>

                <p>
                    <span className="font-medium">{getTranslation('Monday to Friday')}:</span>{' '}
                    {isShopClosed(activeShop, 'monday')
                        ? printAndTranslateClosed('monday')
                        : activeShop.openingHours[getDayValue('monday')] + ' - ' + activeShop.openingHours[getDayValue('monday', false)]}
                </p>

                <p>
                    <span className="font-medium">{getTranslation('Saturday')}:</span>{' '}
                    {isShopClosed(activeShop, 'saturday')
                        ? printAndTranslateClosed('saturday')
                        : activeShop.openingHours[getDayValue('saturday')] +
                          ' - ' +
                          activeShop.openingHours[getDayValue('saturday', false)]}
                </p>

                <p>
                    <span className="font-medium">{getTranslation('Sunday')}:</span>{' '}
                    {isShopClosed(activeShop, 'sunday')
                        ? printAndTranslateClosed('sunday')
                        : activeShop.openingHours[getDayValue('sunday')] + ' - ' + activeShop.openingHours[getDayValue('sunday', false)]}
                </p>
            </div>
        );
    } else {
        return (
            <div className={parentStyling || ''}>
                <h2 className="text-xl font-semibold">{getTranslation('Opening hours')}</h2>

                {weekdays.map(day => (
                    <div key={day}>
                        <span className="font-medium">{getTranslation(day)}:</span>{' '}
                        {isShopClosed(activeShop, day)
                            ? printAndTranslateClosed(day)
                            : activeShop.openingHours![getDayValue(day)] + ' - ' + activeShop.openingHours![getDayValue(day, false)]}
                        <br />
                    </div>
                ))}
            </div>
        );
    }
};
