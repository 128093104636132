import { useContext, useEffect, useState } from 'react';
import { MeetingPlaceContext } from '../../../contexts/meeting-place-provider';
import { useLocalization } from '../../../localization/use-localization';
import { Preference } from '../../visioweb/custom-route';
import { QRCodeSVG } from 'qrcode.react';

type Props = {
    activeFloorPlanId: string | null;
    routePreference: Preference;
};

const QrCodeNav = (props: Props) => {
    const kioskId = useContext(MeetingPlaceContext).kioskId;
    const domain = useContext(MeetingPlaceContext).domain;
    const locale = useContext(MeetingPlaceContext).locale;
    const mapSlug = useContext(MeetingPlaceContext).mapSlug;
    const { activeFloorPlanId, routePreference } = props;
    const { getTranslation } = useLocalization();
    const [url, setUrl] = useState('');
    const [qrSize, setQrSize] = useState(80);

    const handleResize = () => {
        if (window.innerHeight > 1080 && window.innerHeight < 1440) {
            setQrSize(80);
        } else if (window.innerHeight >= 1440 && window.innerHeight <= 2000) {
            setQrSize(100);
        } else if (window.innerHeight > 2000) {
            setQrSize(150);
        } else {
            setQrSize(70);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setUrl('');
    }, [activeFloorPlanId]);

    useEffect(() => {
        let urlVal = '';
        if (domain.includes('http')) {
            if (domain.endsWith('/')) {
                urlVal = `${domain}${locale}/${mapSlug}?kioskId=${kioskId}&destination=${activeFloorPlanId}&routePreference=${routePreference}`;
            } else {
                urlVal = `${domain}/${locale}/${mapSlug}?kioskId=${kioskId}&destination=${activeFloorPlanId}&routePreference=${routePreference}`;
            }
        } else {
            if (domain.endsWith('/')) {
                urlVal = `https://${domain}${locale}/${mapSlug}?kioskId=${kioskId}&destination=${activeFloorPlanId}&routePreference=${routePreference}`;
            } else {
                urlVal = `https://${domain}/${locale}/${mapSlug}?kioskId=${kioskId}&destination=${activeFloorPlanId}&routePreference=${routePreference}`;
            }
        }
        setUrl(urlVal);
    });

    if (!url) {
        return null;
    }

    return (
        <div className="rounded-3xl pb-6">
            <div className="mt-6 flex flex-row justify-between gap-4">
                <div className="flex-1">
                    <div className="flex h-full flex-col justify-center">
                        <div className="text-xl font-bold">{getTranslation('Directions on your phone')}</div>
                        <p className="text-md">{getTranslation('ScanQrCodeNav')}</p>
                    </div>
                </div>
                <div className="flex flex-none items-center justify-center">
                    <div className="rounded-full bg-accent p-8">
                        <QRCodeSVG value={url} bgColor="#FFDA00" level="L" size={qrSize} includeMargin={false} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QrCodeNav;
