import { useContext, useEffect } from 'react';
import { useShops } from '../../../hooks/use-shops';
import { Essential } from '../../../types/visiomap';
import { List } from '../list-components/list';
import { computeRoute } from '../../visioweb/custom-route';
import { MeetingPlaceContext } from '../../../contexts/meeting-place-provider';

type Props = {
    visioWebEssential: Essential | null;
    onClick: (id?: string) => void;
};

export const Services = ({ visioWebEssential, onClick }: Props) => {
    const { kioskId, lang, distances, setDistances } = useContext(MeetingPlaceContext);
    const { getServices } = useShops(visioWebEssential);

    useEffect(() => {
        calculateDistances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function calculateDistances() {
        const allServiceIds = getServices()
            .map(service => service.items)
            .flat()
            .map(item => item.place?.id || null);

        const calculatedDistances = await Promise.all(
            allServiceIds.map(async id => {
                const existingDistance = distances.find(d => d.placeId === id);
                if (existingDistance) {
                    return existingDistance;
                }

                const distance = await getDistanceForService(id);

                return {
                    distance,
                    placeId: id,
                };
            })
        );

        setDistances(calculatedDistances);
    }

    const getDistanceForService = async (placeId?: string | null) => {
        if (!placeId || !visioWebEssential || !kioskId) {
            return 0;
        }

        const route = await computeRoute(visioWebEssential, kioskId, placeId, 'shortest', lang || 'en');
        return route?.data?.length || 0;
    };

    return <List items={getServices()} onClick={onClick} collapsable />;
};
