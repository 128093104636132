import { GraphQLClient, gql } from 'graphql-request';

type fetchMapIdData = {
    libraryMeetingPlace: {
        visioGlobeMapKey: string;
    };
};

export const fetchMapHash = async ({ client, meetingPlaceId }: { client: GraphQLClient; meetingPlaceId: string }) => {
    const document = gql`
        query getMapHash($id: String!) {
            libraryMeetingPlace(id: $id) {
                visioGlobeMapKey
            }
        }
    `;

    const response = await client.request<fetchMapIdData>({
        document,
        variables: {
            id: meetingPlaceId,
        },
    });

    return response.libraryMeetingPlace.visioGlobeMapKey;
};
