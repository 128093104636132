export const Close = ({ fill = '#ffdb00' }) => (
    <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="64" cy="64" r="64" fill={fill} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.8591 84.1431C44.4095 84.689 45.1523 84.9967 45.9274 85C46.3163 85.0022 46.7017 84.9277 47.0617 84.7806C47.4217 84.6336 47.7492 84.417 48.0253 84.1431L64.0062 68.1743L79.9347 84.1429C80.2108 84.4168 80.5383 84.6334 80.8983 84.7804C81.2582 84.9275 81.6437 85.002 82.0326 84.9997C82.4235 84.9981 82.8102 84.919 83.1703 84.7668C83.5304 84.6147 83.8566 84.3925 84.1303 84.1133C84.6806 83.5597 84.9896 82.8108 84.9896 82.0301C84.9896 81.2495 84.6806 80.5006 84.1303 79.947L68.197 63.9867L84.131 48.0648C84.4064 47.7893 84.6251 47.4622 84.7742 47.1023C84.9233 46.7423 85 46.3565 85 45.9669C85 45.5773 84.9233 45.1915 84.7742 44.8315C84.6251 44.4716 84.4064 44.1445 84.131 43.869C83.8555 43.5935 83.5284 43.3749 83.1685 43.2258C82.8086 43.0767 82.4229 43 82.0333 43C81.6437 43 81.2578 43.0767 80.8979 43.2258C80.5379 43.3749 80.2109 43.5935 79.9354 43.869L64.0178 59.8005L48.1131 43.8688C47.5595 43.3185 46.8108 43.0095 46.0302 43.0095C45.2497 43.0095 44.5007 43.3185 43.9472 43.8688C43.6702 44.1435 43.4505 44.4702 43.3005 44.8303C43.1505 45.1904 43.0732 45.5766 43.0732 45.9667C43.0732 46.3567 43.1505 46.743 43.3005 47.103C43.4505 47.4631 43.6702 47.7899 43.9472 48.0646L59.8323 63.9897L43.8591 79.9768C43.3088 80.5304 43 81.2793 43 82.0599C43 82.8405 43.3088 83.5895 43.8591 84.1431Z"
            fill="#474747"
        />
    </svg>
);
