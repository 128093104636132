import { ShopItem } from '../../../hooks/use-shops';

type Props = {
    item: ShopItem;
    distance?: number | null;
    onClick: (id?: string) => void;
};

export const ListItemPlace = ({ item, distance, onClick }: Props) => {
    function renderMetadata() {
        if (item.floor && distance) {
            return (
                <p>
                    <span className="capitalize">{item.floor}</span>, {distance} m
                </p>
            );
        }

        if (item.floor && !distance) {
            return <p className="capitalize">{item.floor}</p>;
        }

        if (!item.floor && distance) {
            return <p>{distance} m</p>;
        }

        return null;
    }

    return (
        <button
            data-component="list-item-place"
            onClick={() => onClick(item.place?.id)}
            className="list-item-place"
            data-tracking="pick-list-item-btn"
        >
            {item.imagePath ? <img src={item.imagePath} alt={item.name} className="w-10 rounded" /> : null}
            <div className="flex w-full flex-col items-start">
                <p className="line-clamp-1 font-bold">{item.name}</p>
                <div className="text-sm">{renderMetadata()}</div>
            </div>
        </button>
    );
};
