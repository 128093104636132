import { LangType } from '../../contexts/meeting-place-provider';
import { Essential, ComputedRoute, Position, CameraPoint } from '../../types/visiomap';
import { VISIO_IMAGE_PATH } from './visiomap';

export type Preference = 'shortest' | 'lift-only' | 'no-lift';

export const computeRoute = async (
    visiowebEssential: Essential,
    kioskId: string,
    floorplanId: string,
    preference: Preference = 'shortest',
    language: LangType
) => {
    try {
        if (!visiowebEssential?._mapviewer) {
            console.error('Error, no visiowebEssential._mapviewer has borked');
            return;
        }

        const result = await visiowebEssential._mapviewer?.computeRoute({
            src: kioskId || '',
            dst: floorplanId || '',
            language,
            routingParameters: {
                excludedAttributes:
                    preference === 'no-lift' ? ['lift'] : preference === 'lift-only' ? ['stairway', 'escalator', 'stair', 'notPRM'] : [],
            },
            computeNavigation: true,
        });

        return result;
    } catch (e) {
        console.error(
            `Error computing route: floorplanId: ${floorplanId}, kioskId: ${kioskId}, preference: ${preference}. Exception: ${e}`
        );
    }
};

export const createRoute = (visiowebEssential: Essential, routeData: ComputedRoute) => {
    try {
        if (!routeData || !visiowebEssential._mapviewer) {
            console.error('Error, no routeData or visiowebEssential._mapviewer has borked');
            return;
        }

        const options = {
            imagePath: VISIO_IMAGE_PATH,
            pathOpacity: 0.75,
            arrowWidth: 4,
            pathWidth: 1.75,
            pathColor: {
                past: '#000',
                current: '#000',
                future: '#000',
            },
        };

        return new window.visioweb.Route(visiowebEssential._mapviewer, routeData?.data || null, options);
    } catch (e) {
        console.error(`Error creating route: routeData: ${routeData}. Exception: ${e}`);
    }
};

export const moveCameraTo = (visiowebEssential: Essential, computedRoute: ComputedRoute, kioskId: string) => {
    function getLeftPosition() {
        if (window?.innerWidth < 1500) {
            return 400;
        }

        return window?.innerWidth < 2000 ? 500 : 650;
    }

    try {
        const srcPos = visiowebEssential?._mapviewer?.getPOF(kioskId);
        let legPositions: Position[] = [];
        if (srcPos) {
            legPositions = computedRoute.data.legs.find(floor => floor.dataset === srcPos.floor)?.points || [];
        }

        const legPoints: CameraPoint[] = legPositions.map(position => {
            const tempPoint = visiowebEssential._mapviewer!.convertLatLonToPoint(position);
            return { x: tempPoint.x, y: tempPoint.y, radius: 0 };
        });

        const viewpointPosition = visiowebEssential._mapviewer!.getViewpointPosition({
            points: legPoints,
            top: 75,
            bottom: 75,
            left: getLeftPosition(),
            right: 75,
        });
        visiowebEssential._mapviewer!.multiBuildingView.setCurrentFloor(srcPos ? srcPos.floor : '');
        visiowebEssential._mapviewer?.multiBuildingView.goTo({
            viewpoint: { position: viewpointPosition },
        });

        return viewpointPosition;
    } catch (e) {
        console.error(
            `Failed to move camera to computed route. Computed route was: ${computeRoute}. Kiosk was: ${kioskId}. Error was: ${e}`
        );
        return null;
    }
};
