import { Landing } from '../components/landing/landing';
import { VisioMap } from '../components/visioweb/visiomap';
import { OffersListing } from '../components/offers/offers-listing';
import { SingleOffer } from '../components/offers/single-offer';
import { EventsListing } from '../components/mp-events/events-listing';
import { SingleEvent } from '../components/mp-events/single-event';
import { Information } from '../components/information/information';
import { Feedback } from '../components/feedback/feedback';

export const routes = [
    { index: true, path: '/', element: <Landing /> },
    { path: '/select-kiosk', element: <VisioMap pickKiosk /> },
    { path: '/map', element: <VisioMap /> },
    { path: '/offers', element: <OffersListing /> },
    { path: '/offers/:id', element: <SingleOffer /> },
    { path: '/events', element: <EventsListing /> },
    { path: '/events/:id', element: <SingleEvent /> },
    { path: '/information', element: <Information /> },
    { path: '/feedback', element: <Feedback /> },
];
