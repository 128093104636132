import clsx from 'clsx';

export const Tags = ({ tags = [] }: { tags?: { name: string }[] }) => {
    if (!Array.isArray(tags) || !tags?.length) {
        return null;
    }
    return (
        <div data-component="tags" className="flex gap-2 pt-8">
            {tags.map(({ name }, index) => (
                <div
                    key={index}
                    className={clsx('rounded-xl border-2 border-[#adaaa3] px-4 py-2 text-sm font-semibold', {
                        'border-[#8EBDA8] bg-[#C0D9CA]': index === 0,
                    })}
                >
                    {name}
                </div>
            ))}
        </div>
    );
};
