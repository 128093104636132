import { Essential, Place } from '../../types/visiomap';
import { ShopType } from '../../services/meeting-place/shops';

export const resetPlaceColors = (visioWebEssential: Essential | null, shops: ShopType[]) => {
    if (!visioWebEssential) {
        return;
    }

    shops.forEach(shop => {
        if (!shop?.floorPlanIDs?.length) {
            return;
        }

        const places = shop.floorPlanIDs
            .map(floorPlanID => visioWebEssential!._mapviewer!.getPlace(floorPlanID))
            .filter(p => p)
            .map(p => p as Place); // Need this for the TS parser for some reason, despite the previous filter

        visioWebEssential?._mapviewer?.resetPlaceColor(places);
    });
};

export const setActivePlaceColor = (visioWebEssential: Essential | null, activePlaceId?: string | null) => {
    if (!visioWebEssential || !activePlaceId) {
        return;
    }

    const activePlace = visioWebEssential!._mapviewer!.getPlace(activePlaceId);
    if (activePlace) {
        visioWebEssential?._mapviewer?.setPlaceColor(activePlace, '#fff');
    }
};
