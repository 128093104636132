import { gql } from 'graphql-request';
import { BaseFetchType } from './offers';
import { Document } from '@contentful/rich-text-types';

type fetchWayfinderSettingsData = {
    libraryMeetingPlace: WayfinderSettingsData;
};

export type SettingsItemType = {
    name: string;
    body: {
        json: Document | null;
    };
    image: {
        url: string;
    };
    menuText: string;
    internalName: string;
};

export type WayfinderSettingsData = {
    name: string;
    socialMediaLinks: {
        webUrl: string;
    };
    offerListPage: {
        slug: string;
    };
    eventListPage: {
        slug: string;
    };
    wayfinderSettings: {
        loadingScreen: {
            url?: string;
        };
        informationMenuCollection: {
            items: SettingsItemType[];
        };
        sys: {
            id: string;
        };
        showEvents: boolean | null;
        showOffers: boolean | null;
    };
};

export const fetchMeetingPlaceWayfinderSettings = async ({
    client,
    meetingPlaceId,
    locale,
}: Omit<BaseFetchType, 'tagId'> & {
    meetingPlaceId: string;
}) => {
    const document = gql`
        query getSettings($id: String!, $locale: String) {
            libraryMeetingPlace(id: $id, locale: $locale) {
                name
                socialMediaLinks {
                    webUrl
                }
                offerListPage {
                    slug
                }
                eventListPage {
                    slug
                }
                wayfinderSettings {
                    loadingScreen {
                        url
                    }
                    informationMenuCollection {
                        items {
                            name
                            body {
                                json
                            }
                            image {
                                url
                            }
                            menuText
                            internalName
                        }
                    }
                    sys {
                        id
                    }
                    showEvents
                    showOffers
                }
            }
        }
    `;

    const response = await client.request<fetchWayfinderSettingsData>({
        document,
        variables: {
            locale,
            id: meetingPlaceId,
        },
    });

    return response.libraryMeetingPlace;
};
