import clsx from 'clsx';
import { useLocalization } from '../../../localization/use-localization';
import { Preference } from '../../visioweb/custom-route';

type Props = {
    routePreference: Preference;
    setRoutePreference: (choice: Preference) => void;
};

type Choice = {
    key: Preference;
    label: string;
};

export const NavigationChoiceToggle = ({ routePreference, setRoutePreference }: Props) => {
    const { getTranslation } = useLocalization();

    const choices: Choice[] = [
        {
            key: 'shortest',
            label: getTranslation('Shortest'),
        },
        {
            key: 'lift-only',
            label: getTranslation('Accessible'),
        },
        {
            key: 'no-lift',
            label: getTranslation('No elevator'),
        },
    ];

    return (
        <div className="flex h-10 rounded-full border-2 border-[#d7cfc6] font-bold leading-none xl:gap-2">
            {choices.map(({ key, label }, index) => (
                <div key={key}>
                    <input
                        id={key}
                        type="radio"
                        value={key}
                        className="peer hidden"
                        name="navigation-choice"
                        checked={routePreference === key}
                        onChange={() => setRoutePreference(key)}
                    />
                    <label
                        htmlFor={key}
                        className={clsx(
                            'mt-[-2px] grid h-10 cursor-pointer place-content-center overflow-hidden rounded-full border-white px-2 text-sm peer-checked:border-2 peer-checked:border-default max-xl:text-xs xl:px-4',
                            {
                                'ml-[-2px]': index === 0,
                                'mr-[-2px]': index === 2,
                            }
                        )}
                    >
                        {label}
                    </label>
                </div>
            ))}
        </div>
    );
};
