import React, { TouchEventHandler } from 'react';
import { Floor } from '../../../types/visiomap';
import clsx from 'clsx';

type FloorSelectorProps = {
    floors: FloorWithLocalizedName[];
    selectedFloor?: Floor | null;
    onPickFloor: (floor: Floor) => void;
    onTouchStart: TouchEventHandler<HTMLButtonElement>;
    onTouchEnd: TouchEventHandler<HTMLButtonElement>;
};

export type FloorWithLocalizedName = Floor & { localizedShortName: string };

export const FloorSelector: React.FC<FloorSelectorProps> = ({ floors, onPickFloor, selectedFloor = null, onTouchEnd, onTouchStart }) => {
    if (floors.length === 1) {
        return null;
    }

    return (
        <div className="space-y-3 pt-6">
            {floors.map(floor => (
                <button
                    className={clsx('rounded-button', {
                        'bg-accent': selectedFloor?.id === floor.id,
                    })}
                    key={floor.levelIndex}
                    onClick={() => onPickFloor(floor)}
                    onTouchEnd={onTouchEnd}
                    onTouchStart={onTouchStart}
                >
                    {floor.localizedShortName}
                </button>
            ))}
        </div>
    );
};
