import { useCallback, useContext } from 'react';
import { MeetingPlaceContext } from '../contexts/meeting-place-provider';
import { getShopName } from '../helpers/shops';

type searchTerm = {
    searchTerm: string;
    count: number;
};

export const usePopularSearch = () => {
    const { services, shops, foodDrink } = useContext(MeetingPlaceContext);

    const addPopularSearch = (searchTerm: string) => {
        const popularSearch = getAllPopularSearch();
        const index = popularSearch.findIndex((item: searchTerm) => item.searchTerm === searchTerm);

        if (index === -1) {
            popularSearch.push({ searchTerm, count: 1 });
        } else {
            popularSearch[index].count += 1;
        }

        setPopularSearch(popularSearch);
    };

    //function for storing an array of popular search terms in local storage
    const setPopularSearch = (popularSearch: string[]) => {
        localStorage?.setItem('popularSearch', JSON.stringify(popularSearch));
    };

    //function for getting top X popular search terms from local storage
    const getTopXPopularSearchTerms = (number = 5) => {
        const popularSearch = getAllPopularSearch();
        return popularSearch
            .sort((a: searchTerm, b: searchTerm) => b.count - a.count)
            .slice(0, number)
            .map((item: searchTerm) => item.searchTerm) as string[];
    };

    //function for getting an array of popular search terms from local storage
    const getAllPopularSearch = () => {
        const popularSearch = localStorage?.getItem('popularSearch');
        return popularSearch ? JSON.parse(popularSearch) : [];
    };

    const getFormattedPopularSearchTerms = (number = 5) => {
        const allTentantNames = [...shops.items, ...foodDrink.items, ...services.items];
        const searchTerms = getTopXPopularSearchTerms(number);

        return searchTerms
            .map(place => {
                const tenant = allTentantNames.find(tenant => tenant.floorPlanIDs?.includes(place));
                if (!tenant) {
                    return {
                        place,
                        name: null,
                    };
                }

                return {
                    place,
                    name: getShopName(tenant),
                };
            })
            .filter(s => s.name);
    };

    const resetPopularSearch = useCallback(() => {
        localStorage?.removeItem('popularSearch');
    }, []);

    return {
        addPopularSearch,
        setPopularSearch,
        getTopXPopularSearchTerms,
        getFormattedPopularSearchTerms,
        resetPopularSearch,
    };
};
