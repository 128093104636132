import { LangType } from '../contexts/meeting-place-provider';
import enFile from './translations/en-XZ.json';
import csFile from './translations/cs-CZ.json';
import esFile from './translations/es-ES.json';
import frFile from './translations/fr-FR.json';
import itFile from './translations/it-IT.json';
import plFile from './translations/pl-PL.json';
import ptFile from './translations/pt-PT.json';
import skFile from './translations/sk-SK.json';
import svFile from './translations/sv-SE.json';
import zhFile from './translations/zh-CN.json';
import { replaceNullWithQuotesJson } from '../utils/methods';

export const navigationTranslator = (locale: LangType) => {
    let translation = {};
    switch (locale) {
        case 'sv':
            translation = {
                maneuvers: replaceNullWithQuotesJson(svFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(svFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(svFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(svFile.durations, locale)),
            };
            break;
        case 'en':
            translation = {
                maneuvers: replaceNullWithQuotesJson(enFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(enFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(enFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(enFile.durations, locale)),
            };
            break;
        case 'pt':
            translation = {
                maneuvers: replaceNullWithQuotesJson(ptFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(ptFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(ptFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(ptFile.durations, locale)),
            };
            break;
        case 'zh-CN':
            translation = {
                maneuvers: replaceNullWithQuotesJson(zhFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(zhFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(zhFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(zhFile.durations, locale)),
            };
            break;
        case 'pl':
            translation = {
                maneuvers: replaceNullWithQuotesJson(plFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(plFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(plFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(plFile.durations, locale)),
            };
            break;
        case 'fr':
            translation = {
                maneuvers: replaceNullWithQuotesJson(frFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(frFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(frFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(frFile.durations, locale)),
            };
            break;
        case 'es':
            translation = {
                maneuvers: replaceNullWithQuotesJson(esFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(esFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(esFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(esFile.durations, locale)),
            };
            break;
        case 'it':
            translation = {
                maneuvers: replaceNullWithQuotesJson(itFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(itFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(itFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(itFile.durations, locale)),
            };
            break;
        case 'cs':
            translation = {
                maneuvers: replaceNullWithQuotesJson(csFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(csFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(csFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(csFile.durations, locale)),
            };
            break;
        case 'sk':
            translation = {
                maneuvers: replaceNullWithQuotesJson(skFile.maneuvers),
                nextManeuvers: replaceNullWithQuotesJson(skFile.nextManeuvers),
                keywords: replaceNullWithQuotesJson(skFile.keywords),
                durations: replaceNullWithQuotesJson(createCustomDurationSetup(skFile.durations, locale)),
            };
            break;
        default:
            break;
    }
    return translation;
};

// We are using durations in a different way because
// Slovensky language has different minutes notation for
// different numbers
const createCustomDurationSetup = (durations: { [key: string]: string | null }, locale: LangType) => {
    const result: any = {
        minutes: durations.minutes,
        minutes_before_instruction: durations.minutes_before_instruction,
        oneMinute: durations.oneMinute,
        oneMinute_before_instruction: durations.oneMinute_before_instruction,
        seconds: durations.seconds,
        seconds_before_instruction: durations.seconds_before_instruction,
    };

    if (locale == 'sk') {
        result.customRangeTimeArray = [
            { rangeMin: 0.0, rangeMax: 1.0, time: durations.less_than_one_minute },
            { rangeMin: 1.0, rangeMax: 2.0, time: durations.one_minute_custom },
            { rangeMin: 2.0, rangeMax: 4.0, time: durations.from_two_to_four_minutes },
            { rangeMin: 4.0, rangeMax: 5.0, time: durations.from_two_to_four_minutes },
            { rangeMin: 5.0, rangeMax: 20.0, time: durations.more_than_five_minutes },
        ];
    }
    return result;
};
