import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import { Event } from './event';
import { Layout } from '../layout/layout';
import { getRichTextRendererOptions } from '../../helpers/rich-text-renderer-options';
import { CmsText } from '../cms-text';
import { useEvents } from '../../hooks/use-events';

export const SingleEvent = () => {
    const { id } = useParams();
    const { getEventById } = useEvents();
    const event = getEventById(id);
    const { settings, locale, libraryUrlSubdirectoryCollection, domain } = useContext(MeetingPlaceContext);

    if (!event) {
        return <div>404</div>;
    }

    const contentfulRendererOptions = getRichTextRendererOptions(
        event.body.links,
        settings.settings,
        locale || 'en',
        libraryUrlSubdirectoryCollection.items,
        domain
    );

    return (
        <Layout data-component="single-event" showBackArrow>
            <div className="grid grid-cols-2 items-start gap-8">
                <Event event={event} size="large" classNames="cursor-auto" />
                <div className="relative space-y-8 rounded bg-beige px-12 py-20">
                    <h1 className="text-2xl font-bold">{event.name}</h1>
                    <CmsText json={event.body.json} rendererOptions={contentfulRendererOptions} />
                </div>
            </div>
        </Layout>
    );
};
