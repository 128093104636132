/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';

const MapView: React.FC = () => (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 419.26 344.61" style={{ height: '100%', width: 'auto' }}>
        <defs>
            <style>{`
        .cls-1 { fill: #b8b2a9; }
        .cls-2 { fill: #e7e3d7; }
        .cls-3 { fill: #d4cdc2; }
      `}</style>
        </defs>
        <g id="Layer_2-2" data-name="Layer_2">
            <g>
                <rect className="cls-2" width="419.26" height="344.61" />
                <polygon className="cls-1" points="136.05 328.47 144.28 266.8 10.44 255.24 10.44 326.42 136.05 328.47" />
                <polygon
                    className="cls-1"
                    points="145.91 262.01 153.88 195.32 138.57 175.65 89.04 172.04 70.02 144.15 10.44 137.37 10.44 249.6 145.91 262.01"
                />
                <polygon
                    className="cls-1"
                    points="10.44 127.88 75.57 134.96 88.63 153.3 128.69 120.54 61.22 37.39 62.17 14.59 10.44 14.59 10.44 127.88"
                />
                <polygon className="cls-1" points="70.01 14.59 69.73 33.99 135.88 116.09 160.14 95.63 168.71 14.59 70.01 14.59" />
                <polygon className="cls-1" points="175.33 14.59 167.86 91.13 224.15 96.73 235.5 14.59 175.33 14.59" />
                <polygon className="cls-1" points="202.03 124.05 198.37 157.59 277.44 166.15 281.6 132.62 202.03 124.05" />
                <polygon className="cls-1" points="236.92 64.24 288.45 70.19 286.49 89.78 274.01 101.04 232.23 96.63 236.92 64.24" />
                <polygon className="cls-1" points="239.98 39.28 237.34 57.71 288.45 63.67 290.48 44.57 239.98 39.28" />
                <polygon className="cls-1" points="240.69 33.55 290.9 38.29 293.35 14.59 242.92 14.59 240.69 33.55" />
                <polygon className="cls-1" points="239.1 181.04 236.77 204.61 275.03 208.28 276.27 195.55 265.25 182.88 239.1 181.04" />
                <polygon className="cls-1" points="236.49 207.82 231.44 252.82 269.29 256.46 274.51 211.37 236.49 207.82" />
                <polygon className="cls-1" points="231.16 256.77 268.62 260.26 266.91 276.86 229.37 273.03 231.16 256.77" />
                <polygon className="cls-1" points="229.08 277.26 266.54 281.11 263.05 314.89 225.02 311.21 229.08 277.26" />
                <polygon className="cls-1" points="224.73 315.94 262.32 319.55 260.66 336.59 222.32 336.59 224.73 315.94" />
                <polygon className="cls-1" points="180.16 311.83 219.48 315.7 217.14 336.59 177.04 336.59 180.16 311.83" />
                <polygon className="cls-1" points="235.63 180.9 209.44 179.39 194.93 191.88 194.01 200.87 233.33 204.73 235.63 180.9" />
                <polygon className="cls-1" points="389.75 269.99 321.72 262.01 307.2 274.5 304.54 308.16 386.23 315.46 389.75 269.99" />
                <polygon className="cls-1" points="385.66 319.9 304.55 313.12 303.03 336.45 384.7 336.59 385.66 319.9" />
                <polygon className="cls-1" points="193.14 204.13 232.74 208.01 231.37 220.58 191.99 216.7 193.14 204.13" />
                <polygon className="cls-1" points="191.55 220.43 230.74 224.31 225.44 272.06 186.34 268.18 191.55 220.43" />
                <polygon className="cls-1" points="185.55 273.05 224.73 276.75 220.57 310.82 180.9 306.83 185.55 273.05" />
                <polygon className="cls-1" points="334.78 14.59 331.47 53.74 408.44 60.77 411.23 14.59 334.78 14.59" />
                <polygon className="cls-1" points="330.78 59.84 327.1 103.86 404.15 110.82 406.83 66.47 330.78 59.84" />
                <polygon
                    className="cls-1"
                    points="326.32 109.68 323.58 142.41 370.24 147.69 369.45 161.04 399.56 163.99 403.22 117.45 326.32 109.68"
                />
                <polygon
                    className="cls-1"
                    points="364.39 151.82 363.77 160.68 345.26 158.88 344.1 172.81 321.15 170.69 323.31 147.43 364.39 151.82"
                />
                <polygon
                    className="cls-1"
                    points="399.76 169.63 349.86 165.09 348.77 178.16 320.73 175.59 317.2 208.28 322.23 215.36 394.73 223.64 399.76 169.63"
                />
                <polygon className="cls-1" points="311.71 95.35 302.41 93.94 305.25 69 314.55 70.27 311.71 95.35" />
                <polygon className="cls-1" points="169.11 273.4 161.11 272.52 163.26 257.04 171.26 257.83 169.11 273.4" />
                <polygon className="cls-1" points="293.4 278.4 285.4 277.52 286.99 262.18 294.98 262.97 293.4 278.4" />
                <g>
                    <polygon className="cls-3" points="135.72 325.4 143.94 263.73 10.11 252.17 10.11 323.35 135.72 325.4" />
                    <polygon
                        className="cls-3"
                        points="145.57 258.94 153.54 192.25 138.23 172.58 88.71 168.97 69.69 141.08 10.11 134.3 10.11 246.53 145.57 258.94"
                    />
                    <polygon
                        className="cls-3"
                        points="10.11 124.17 75.24 131.25 88.3 149.59 128.36 116.83 60.89 33.68 61.84 10.88 10.11 10.88 10.11 124.17"
                    />
                    <polygon className="cls-3" points="69.69 10.88 69.41 30.28 135.56 112.38 159.82 91.92 168.39 10.88 69.69 10.88" />
                    <polygon className="cls-3" points="175.01 10.88 167.53 87.42 223.82 93.02 235.18 10.88 175.01 10.88" />
                    <polygon className="cls-3" points="201.71 120.34 198.04 153.88 277.12 162.44 281.28 128.91 201.71 120.34" />
                    <polygon className="cls-3" points="236.6 60.53 288.13 66.48 286.16 86.07 273.68 97.33 231.91 92.91 236.6 60.53" />
                    <polygon className="cls-3" points="239.66 35.57 237.02 54 288.13 59.96 290.16 40.86 239.66 35.57" />
                    <polygon className="cls-3" points="240.37 29.84 290.58 34.58 293.03 10.88 242.61 10.88 240.37 29.84" />
                    <polygon
                        className="cls-3"
                        points="238.79 177.33 236.45 200.9 274.71 204.57 275.96 191.84 264.93 179.17 238.79 177.33"
                    />
                    <polygon className="cls-3" points="236.17 204.11 231.13 249.1 268.98 252.75 274.2 207.66 236.17 204.11" />
                    <polygon className="cls-3" points="230.85 253.06 268.3 256.56 266.6 273.15 229.05 269.33 230.85 253.06" />
                    <polygon className="cls-3" points="228.77 273.56 266.23 277.41 262.74 311.18 224.71 307.51 228.77 273.56" />
                    <polygon className="cls-3" points="224.43 312.23 261.01 315.84 259.35 332.88 221.02 332.88 224.43 312.23" />
                    <polygon className="cls-3" points="179.85 308.12 219.17 311.99 216.83 332.88 176.73 332.88 179.85 308.12" />
                    <polygon className="cls-3" points="235.31 177.18 209.12 175.67 194.61 188.16 193.69 197.14 233.01 201 235.31 177.18" />
                    <polygon className="cls-3" points="389.43 266.27 321.4 258.29 306.88 270.78 304.22 304.44 385.9 311.74 389.43 266.27" />
                    <polygon className="cls-3" points="385.34 316.18 304.22 309.41 302.7 332.73 384.38 332.88 385.34 316.18" />
                    <polygon className="cls-3" points="192.82 200.41 232.42 204.29 231.05 216.86 191.67 212.98 192.82 200.41" />
                    <polygon className="cls-3" points="191.24 216.71 230.42 220.59 225.12 268.34 186.02 264.46 191.24 216.71" />
                    <polygon className="cls-3" points="185.23 269.33 224.42 273.04 220.26 307.11 180.59 303.11 185.23 269.33" />
                    <polygon className="cls-3" points="334.46 10.88 331.15 50.03 408.12 57.05 410.91 10.88 334.46 10.88" />
                    <polygon className="cls-3" points="330.46 56.13 326.78 100.15 403.83 107.11 406.51 62.76 330.46 56.13" />
                    <polygon
                        className="cls-3"
                        points="325.99 106 323.25 138.73 369.91 143.98 369.13 157.33 399.23 160.29 402.89 113.75 325.99 106"
                    />
                    <polygon
                        className="cls-3"
                        points="364.07 148.1 363.45 157 344.94 155.2 343.78 169.12 320.83 166.99 322.98 143.74 364.07 148.1"
                    />
                    <polygon
                        className="cls-3"
                        points="399.44 165.91 349.54 161.36 348.45 174.43 320.41 171.86 316.88 204.57 321.91 211.65 394.41 219.93 399.44 165.91"
                    />
                    <polygon className="cls-3" points="311.39 91.64 302.1 90.23 304.93 65.3 314.23 66.56 311.39 91.64" />
                    <polygon className="cls-3" points="168.79 269.68 160.79 268.81 162.94 253.32 170.94 254.12 168.79 269.68" />
                    <polygon className="cls-3" points="293.08 274.68 285.08 273.81 286.67 258.46 294.65 259.25 293.08 274.68" />
                </g>
            </g>
        </g>
    </svg>
);

export default MapView;
