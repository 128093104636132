export const Settings = () => (
    <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="64" cy="64" r="64" fill="#f7f4ef" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 52C40 49.7909 41.7909 48 44 48H84C86.2091 48 88 49.7909 88 52C88 54.2091 86.2091 56 84 56H44C41.7909 56 40 54.2091 40 52ZM40 64C40 61.7909 41.7909 60 44 60H84C86.2091 60 88 61.7909 88 64C88 66.2091 86.2091 68 84 68H44C41.7909 68 40 66.2091 40 64ZM88 76C88 73.7909 86.2091 72 84 72H44C41.7909 72 40 73.7909 40 76C40 78.2091 41.7909 80 44 80H84C86.2091 80 88 78.2091 88 76Z"
            fill="#474747"
        />
    </svg>
);
