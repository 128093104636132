import { useCallback, useContext, useMemo } from 'react';
import { MeetingPlaceContext } from '../contexts/meeting-place-provider';
import { ShopType } from '../services/meeting-place/shops';
import { ServiceType } from '../services/meeting-place/services';
import { useLocation } from 'react-router-dom';
import { useLocalization } from '../localization/use-localization';

/**
 * All events will need these 3 properties.
 */
export type EventObject = {
    domain: string;
    page: string;
    market: string;
    customLanguage: string;
    fromKioskId: string;
    mpTagId: string;
    customSessionId: string;
    /**
     * If this parameter exists, the event will automatically be treated as debug
     */
    debug_mode?: boolean;
};

/**
 * All tracking automatically appends custom properties and page location. Automatically adds debug mode if in dev mode.
 */
export const useTracking = () => {
    const EVENT_NAME_PREFIX = 'wf01';
    const { kioskId, mpTagId, shops, foodDrink, services } = useContext(MeetingPlaceContext);
    const { pathname } = useLocation();
    const { lang, locale } = useContext(MeetingPlaceContext);
    const { getCustomCountryCode } = useLocalization();

    const eventGeneralObject: EventObject = useMemo(() => {
        const debugMode = {
            debug_mode: true,
        };
        const eventObject = {
            domain: window.location.host,
            page: pathname,
            market: getCustomCountryCode(locale || '') || '',
            customLanguage: lang || '',
            fromKioskId: kioskId || '',
            mpTagId: mpTagId || '',
            customSessionId: `${kioskId || ''}-${localStorage.getItem('customSessionId') || ''}`,
        };

        return window.location.host.includes('localhost') ? { ...eventObject, ...debugMode } : eventObject;
    }, [getCustomCountryCode, kioskId, lang, locale, mpTagId, pathname]);

    const trackNavigation = useCallback(
        (floorPlanId: string, currentNavInstruction: number, navigationPlayback?: 'start' | 'change' | 'end') => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX}_floorplan_wayfinding`;

            let destinationName;
            const shopsAndFood = [...shops.items, ...foodDrink.items];

            const foundShopOrFood = shopsAndFood.find(destination => {
                if (!destination.floorPlanIDs) {
                    return false;
                }

                return destination.floorPlanIDs!.includes(floorPlanId);
            }) as ShopType;

            if (foundShopOrFood) {
                destinationName = foundShopOrFood?.name || foundShopOrFood?.brand?.name || 'Name is missing';
            } else {
                const service = services.items.find(destination => {
                    if (!destination.floorPlanIDs) {
                        return false;
                    }
                    return destination.floorPlanIDs!.includes(floorPlanId);
                }) as ServiceType;

                destinationName = service?.name || service?.serviceType?.name || 'Name is missing';
            }

            const eventSpecificObject = {
                event: EVENT_NAME,
                toStoreName: destinationName,
                currentNavInstruction: currentNavInstruction,
                navigationPlayback: navigationPlayback || '',
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject, foodDrink.items, shops.items, services.items]
    );

    const trackSearch = useCallback(
        (searchTerm: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX}_user_searched`;
            const eventSpecificObject = {
                event: EVENT_NAME,
                searchTerm: searchTerm,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject]
    );

    /**
     * GA automatically tracks page views and since we're using routes, it should know where we are.
     * But we also need some extra data that the automatic tracking doesn't use.
     * Unless we configure the tag to collect that specific data but then we'd need to put it somewhere accessible.s
     * @param location - Supply a location to override the default location from useLocation()
     */
    const trackPage = useCallback(
        (location?: string) => {
            const EVENT_NAME = `${EVENT_NAME_PREFIX}_viewed_page`;

            const eventSpecificObject = {
                event: EVENT_NAME,
                page: location || pathname,
            };
            const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
            window.dataLayer.push(mergedEventObject);
        },
        [eventGeneralObject, pathname]
    );

    const trackSessionStart = useCallback(() => {
        const EVENT_NAME = `${EVENT_NAME_PREFIX}_session_start`;
        const eventSpecificObject = {
            event: EVENT_NAME,
        };
        const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
        window.dataLayer.push(mergedEventObject);
    }, [eventGeneralObject]);

    const trackSessionEnd = useCallback(() => {
        const EVENT_NAME = `${EVENT_NAME_PREFIX}_session_end`;
        const eventSpecificObject = {
            event: EVENT_NAME,
        };
        const mergedEventObject = { ...eventGeneralObject, ...eventSpecificObject };
        window.dataLayer.push(mergedEventObject);
        generateNewSessionId();
    }, [eventGeneralObject]);

    return { trackNavigation, trackSearch: trackSearch, trackPage, trackSessionStart, trackSessionEnd };
};

export const generateNewSessionId = () => {
    const date = new Date();
    const ms = date.getMilliseconds();
    const s = date.getSeconds();
    const m = date.getMinutes();
    const h = date.getHours();
    const d = date.getDate();
    const mo = date.getMonth();
    const y = date.getFullYear();
    const timestamp = `${y}${mo}${d}${h}${m}${s}${ms}`;

    localStorage.setItem('customSessionId', timestamp);
};
