import { gql } from 'graphql-request';
import { BaseFetchType } from './offers';

type FooterData = {
    meetingPlacePageHomeCollection: {
        items: FooterType[];
    };
};

export type FooterType = {
    internalName: string;
    footer?: {
        feedbackUrlWayfinder?: string;
    };
};

export const fetchMeetingPlaceWayfinderFooter = async ({ client, tagId, locale }: BaseFetchType) => {
    const document = gql`
        query getFooter($locale: String, $tagId: [String]) {
            meetingPlacePageHomeCollection(locale: $locale, where: { contentfulMetadata: { tags: { id_contains_some: $tagId } } }) {
                items {
                    internalName
                    footer {
                        ... on BlockFooter {
                            feedbackUrlWayfinder
                        }
                    }
                }
            }
        }
    `;

    const response = await client.request<FooterData>({
        document,
        variables: {
            locale,
            tagId: [tagId],
        },
    });

    return response.meetingPlacePageHomeCollection.items || null;
};
