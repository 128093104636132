import clsx, { ClassValue } from 'clsx';
import { Link } from 'react-router-dom';
import { useLocalization } from '../../localization/use-localization';
import { OccasionType } from '../../services/meeting-place/occasions';
import { Tags } from './tags';

type Props = {
    event: OccasionType;
    classNames?: ClassValue;
    size?: 'small' | 'large';
};

export const Event = ({ event, classNames = '', size = 'small' }: Props) => {
    const { formatDate } = useLocalization();

    const eventDate = () => {
        const formattedStartDate = formatDate(event.startDate);
        const formattedEndDate = formatDate(event.endDate);

        if (formattedStartDate === formattedEndDate) {
            return `${formattedStartDate}`;
        }

        return `${formattedStartDate} - ${formattedEndDate}`;
    };

    return (
        <Link
            to={`/events/${event.sys.id}`}
            className={clsx('overflow-hidden rounded bg-beige', classNames)}
            data-tracking="view-event-btn"
        >
            {event?.coverImage?.url ? <img src={event.coverImage.url} className="rounded" /> : null}
            <div
                className={clsx({
                    'space-y-2 p-5 pb-8 pt-4': size === 'small',
                    'space-y-4 p-6 pb-10 pt-5 text-xl': size === 'large',
                })}
            >
                <p className="font-bold text-light">{eventDate()}</p>
                <h2
                    className={clsx('font-bold', {
                        'text-lg': size === 'small',
                        'text-xl': size === 'large',
                    })}
                >
                    {event.name}
                </h2>
                <Tags tags={event.eventTypesCollection?.items || []} />
            </div>
        </Link>
    );
};
