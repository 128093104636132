/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { GraphQLClient } from 'graphql-request';

import { getParamFromUrlOrDOM } from '../helpers/get-param';
import { fetchLibraryMeetingPlaceId, fetchLibraryLanguageCode } from '../services/meeting-place/meeting-place-data';
import { fetchMapHash } from '../services/meeting-place/visioweb-maphash';
import { OfferType, fetchPaginatedOffers } from '../services/meeting-place/offers';
import { ServiceType, fetchMeetingPlacePageServices } from '../services/meeting-place/services';
import { ShopType, fetchPaginatedMeetingPlaceTenantShops } from '../services/meeting-place/shops';
import { OccasionType, fetchMeetingPlaceOccasions } from '../services/meeting-place/occasions';
import { FeedbackItemType, fetchMeetingPlaceFeedback } from '../services/meeting-place/feedback';
import { FoodDrinkType, fetchPaginatedMeetingPlaceTenantFoodDrink } from '../services/meeting-place/food-drink';
import { WayfinderSettingsData, fetchMeetingPlaceWayfinderSettings } from '../services/meeting-place/wayfinder';
import { serviceWorkerCache } from '../helpers/service-worker';
import { FooterType, fetchMeetingPlaceWayfinderFooter } from '../services/meeting-place/footer';
import {
    LibraryUrlSubdirectoryItem,
    fetchLibraryUrlSubdirectoryCollection,
} from '../services/meeting-place/library-url-subdirectory-collection';
import { fetchMeetingPlacePageDomain } from '../services/meeting-place/domain';
import { fetchMapSlug } from '../services/meeting-place/map-slug';

type DataType<T> = {
    isLoading: boolean;
    loaded: boolean;
    items: T[];
};

export type LangType = 'en' | 'es' | 'fr' | 'sk' | 'pl' | 'pt' | 'it' | 'sv' | 'cs' | 'zh-CN';

export type DistanceType = {
    placeId: string | null;
    distance: number | null;
};

type MeetingPlaceContextType = {
    mapHash: string | null;
    mapSlug: string | null;
    appVersion: string;
    appStarted: Date;
    locale: LangType | null;
    /**
     * Used for switching language in the UI, do not confuse with locale
     */
    lang: LangType | null;
    setLang: (lang: LangType) => void;
    meetingPlaceId: string | null;
    domain: string;
    offers: DataType<OfferType>;
    services: DataType<ServiceType>;
    shops: DataType<ShopType>;
    libraryUrlSubdirectoryCollection: DataType<LibraryUrlSubdirectoryItem>;
    occasions: DataType<OccasionType>;
    feedback: DataType<FeedbackItemType>;
    foodDrink: DataType<FoodDrinkType>;
    settings: Omit<DataType<WayfinderSettingsData>, 'items'> & {
        settings: WayfinderSettingsData | null;
    };
    footer: DataType<FooterType>;
    kioskId: string | null;
    setKioskId: (kioskId: string) => void;
    dataLoaded: boolean;
    client: GraphQLClient;
    verticalMode: boolean;
    mpTagId: string | null;
    distances: DistanceType[];
    setDistances: (distances: DistanceType[]) => void;
};

type TagLangType = {
    locale: string;
    tagId: string;
};

const DEFAULT_DATA_TYPE = {
    isLoading: false,
    loaded: false,
    items: [],
};
const DEFAULT_LOADING_TYPE = {
    isLoading: true,
    loaded: false,
    items: [],
};
const DEFAULT_LOADED_TYPE = {
    isLoading: false,
    loaded: true,
    items: [],
};
const DEFAULT_SETTINGS_TYPE = {
    isLoading: false,
    loaded: false,
    settings: null,
};
const SPACE_ID = import.meta.env.VITE_GRAPHQL_SPACE_ID;
const ENVIRONMENT_ID = import.meta.env.VITE_APP_ENV;
const endpoint = `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT_ID}`;

export const MeetingPlaceContext = createContext<MeetingPlaceContextType>({
    mapHash: null,
    mapSlug: null,
    appVersion: '',
    appStarted: new Date(),
    locale: null,
    lang: null,
    setLang: () => {},
    meetingPlaceId: null,
    domain: '',
    offers: DEFAULT_DATA_TYPE,
    services: DEFAULT_DATA_TYPE,
    shops: DEFAULT_DATA_TYPE,
    libraryUrlSubdirectoryCollection: DEFAULT_DATA_TYPE,
    occasions: DEFAULT_DATA_TYPE,
    feedback: DEFAULT_DATA_TYPE,
    foodDrink: DEFAULT_DATA_TYPE,
    settings: DEFAULT_SETTINGS_TYPE,
    footer: DEFAULT_DATA_TYPE,
    kioskId: null,
    setKioskId: () => {},
    dataLoaded: false,
    client: new GraphQLClient(endpoint),
    verticalMode: false,
    mpTagId: null,
    distances: [],
    setDistances: (distances: DistanceType[] = []) => distances,
});

const MeetingPlaceContextProvider: React.FC<PropsWithChildren<{ appVersion: string; appStarted: Date }>> = ({
    children,
    appVersion,
    appStarted,
}) => {
    const tagId = getParamFromUrlOrDOM('tagId');
    const client = new GraphQLClient(endpoint, {
        headers: {
            authorization: `Bearer ${import.meta.env.VITE_GRAPHQL_CONTENTFUL_TOKEN}`,
        },
        errorPolicy: 'ignore',
    });

    const [locale, setLocale] = useState<MeetingPlaceContextType['locale']>(null);
    const [lang, setLang] = useState<MeetingPlaceContextType['lang']>(null);
    const [mapHash, setMapHash] = useState<MeetingPlaceContextType['mapHash']>(null);
    const [mapSlug, setMapSlug] = useState<MeetingPlaceContextType['mapSlug']>(null);
    const [meetingPlaceId, setMeetingPlaceId] = useState<MeetingPlaceContextType['meetingPlaceId']>(null);
    const [domain, setDomain] = useState<MeetingPlaceContextType['domain']>('');
    const [offers, setOffers] = useState<MeetingPlaceContextType['offers']>(DEFAULT_DATA_TYPE);
    const [services, setServices] = useState<MeetingPlaceContextType['services']>(DEFAULT_DATA_TYPE);
    const [shops, setShops] = useState<MeetingPlaceContextType['shops']>(DEFAULT_DATA_TYPE);
    const [libraryUrlSubdirectoryCollection, setLibraryUrlSubdirectoryCollection] =
        useState<MeetingPlaceContextType['libraryUrlSubdirectoryCollection']>(DEFAULT_DATA_TYPE);
    const [occasions, setOccasions] = useState<MeetingPlaceContextType['occasions']>(DEFAULT_DATA_TYPE);
    const [feedback, setFeedback] = useState<MeetingPlaceContextType['feedback']>(DEFAULT_DATA_TYPE);
    const [foodDrink, setFoodDrink] = useState<MeetingPlaceContextType['foodDrink']>(DEFAULT_DATA_TYPE);
    const [settings, setSettings] = useState<MeetingPlaceContextType['settings']>(DEFAULT_SETTINGS_TYPE);
    const [footer, setFooter] = useState<MeetingPlaceContextType['footer']>(DEFAULT_DATA_TYPE);
    const [kioskId, setKioskId] = useState<null | string>(
        new URLSearchParams(window.location.search).get('kioskId') || localStorage?.getItem('kioskDeviceId') || null
    );
    const [distances, setDistances] = useState<DistanceType[]>([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        if (!tagId) {
            throw new Error('TagId not set, exiting');
        }

        const id = await fetchLibraryMeetingPlaceId({ client, tagId });
        if (!id) {
            throw new Error(`Meeting place Id not found, exiting: TagId: ${tagId}`);
        }

        setMeetingPlaceId(id);
    }

    useEffect(() => {
        if (!meetingPlaceId) {
            return;
        }

        getLocale(meetingPlaceId);
        getMapHash(meetingPlaceId);
    }, [meetingPlaceId]);

    useEffect(() => {
        if (!locale || !tagId || !meetingPlaceId) {
            return;
        }
        getMapSlug(meetingPlaceId, locale);
        getOffers({ locale, tagId });
        getServices({ locale, tagId });
        getShops({ locale: lang ? lang : locale, tagId });
        getLibraryUrlSubdirectoryCollection({ locale, tagId });
        getFeedback({ locale, tagId });
        getFoodDrink({ locale, tagId });
        getFooter({ locale, tagId });
        getSettings({ locale, meetingPlaceId });
        getOccasions(locale);
        getDomain({ locale, tagId });
    }, [locale, tagId, meetingPlaceId, lang]);

    async function getLocale(meetingPlaceId: string) {
        const data = await fetchLibraryLanguageCode({ client, meetingPlaceId });
        setLocale(data);
        setLang(data);
    }

    async function getMapHash(meetingPlaceId: string) {
        const data = await fetchMapHash({ client, meetingPlaceId });
        setMapHash(data);
    }

    async function getMapSlug(meetingPlaceId: string, locale: string) {
        const data = await fetchMapSlug({ client, meetingPlaceId, locale });
        setMapSlug(data);
    }

    async function getOffers({ locale, tagId }: TagLangType) {
        setOffers(DEFAULT_LOADING_TYPE);
        const items = await fetchPaginatedOffers({
            client,
            locale: locale,
            tagId,
        });
        setOffers({ ...DEFAULT_LOADED_TYPE, items });
    }

    async function getServices({ locale, tagId }: TagLangType) {
        setServices(DEFAULT_LOADING_TYPE);
        const items = await fetchMeetingPlacePageServices({
            client,
            locale,
            tagId,
        });
        setServices({ ...DEFAULT_LOADED_TYPE, items });
    }

    async function getShops({ locale, tagId }: TagLangType) {
        setShops(DEFAULT_LOADING_TYPE);
        const items = await fetchPaginatedMeetingPlaceTenantShops({
            client,
            locale,
            tagId,
        });
        setShops({ ...DEFAULT_LOADED_TYPE, items });
    }

    async function getDomain({ locale, tagId }: TagLangType) {
        setDomain('');
        const domainVal = await fetchMeetingPlacePageDomain({
            client,
            locale,
            tagId,
        });
        setDomain(domainVal);
    }

    async function getLibraryUrlSubdirectoryCollection({ locale, tagId }: TagLangType) {
        setLibraryUrlSubdirectoryCollection(DEFAULT_LOADING_TYPE);
        const items = await fetchLibraryUrlSubdirectoryCollection({
            client,
            locale,
            tagId,
        });
        setLibraryUrlSubdirectoryCollection({ ...DEFAULT_LOADED_TYPE, items });
    }

    async function getFeedback({ locale, tagId }: TagLangType) {
        setFeedback(DEFAULT_LOADING_TYPE);
        const items = await fetchMeetingPlaceFeedback({
            client,
            locale,
            tagId,
        });
        setFeedback({ ...DEFAULT_LOADED_TYPE, items });
    }

    async function getFoodDrink({ locale, tagId }: TagLangType) {
        setFoodDrink(DEFAULT_LOADING_TYPE);
        const items = await fetchPaginatedMeetingPlaceTenantFoodDrink({
            client,
            locale,
            tagId,
            language: lang ? lang : locale,
        });
        setFoodDrink({ ...DEFAULT_LOADED_TYPE, items });
    }

    async function getOccasions(locale: string) {
        if (!meetingPlaceId) {
            return;
        }

        setOccasions(DEFAULT_LOADING_TYPE);
        const items = await fetchMeetingPlaceOccasions({
            client,
            locale,
            meetingPlaceId,
        });
        setOccasions({ ...DEFAULT_LOADED_TYPE, items: items.filter(item => item?.sys?.id) });
    }

    async function getFooter({ locale, tagId }: TagLangType) {
        setSettings({ ...settings, isLoading: true });
        const data = await fetchMeetingPlaceWayfinderFooter({
            client,
            locale,
            tagId,
        });
        setFooter({ ...DEFAULT_LOADED_TYPE, items: data });
    }

    async function getSettings({ locale, meetingPlaceId }: { locale: string; meetingPlaceId: string }) {
        setSettings({ ...settings, isLoading: true });
        const data = await fetchMeetingPlaceWayfinderSettings({
            client,
            locale,
            meetingPlaceId,
        });
        setSettings({ settings: data, loaded: true, isLoading: false });
    }

    useEffect(() => {
        if (
            offers.loaded &&
            services.loaded &&
            shops.loaded &&
            occasions.loaded &&
            feedback.loaded &&
            foodDrink.loaded &&
            settings.loaded &&
            footer.loaded
        ) {
            setDataLoaded(true);
            serviceWorkerCache({
                offers: offers.items,
                services: services.items,
                shops: shops.items,
                occasions: occasions.items,
                foodDrink: foodDrink.items,
                settings: settings.settings,
            });
        }
    }, [offers, services, shops, occasions, feedback, foodDrink, settings]);

    return (
        <MeetingPlaceContext.Provider
            value={{
                client,
                appVersion,
                appStarted,
                meetingPlaceId,
                domain,
                mapHash,
                mapSlug,
                locale,
                lang,
                setLang,
                offers,
                services,
                shops,
                libraryUrlSubdirectoryCollection,
                occasions,
                feedback,
                foodDrink,
                settings,
                footer,
                dataLoaded,
                kioskId,
                setKioskId,
                verticalMode: !!getParamFromUrlOrDOM('verticalMode'),
                mpTagId: tagId || '',
                distances,
                setDistances,
            }}
        >
            {children}
        </MeetingPlaceContext.Provider>
    );
};

export default MeetingPlaceContextProvider;
