import { useEffect, useRef, useState } from 'react';
import { ListItem as ListItemType } from '../../../hooks/use-shops';
import { ListItem } from './list-item';

type Props = {
    items: ListItemType[];
    onClick: (id?: string) => void;
    collapsable?: boolean;
    className?: string; // Add classNames prop
    inlineStyle?: React.CSSProperties; // Add inlineStyle prop
};

export const List = ({ items, className, inlineStyle, ...rest }: Props) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [lastItem, setLastItem] = useState<Element | null | undefined>(null);

    useEffect(() => {
        if (!containerRef) {
            return;
        }
        const item = containerRef.current?.lastElementChild;
        setLastItem(item);
    }, [containerRef]);

    return (
        <div data-component="list" ref={containerRef} className={className} style={inlineStyle}>
            {items.map((item, index) => (
                <ListItem key={index} item={item} {...rest} lastItem={lastItem} />
            ))}
        </div>
    );
};
