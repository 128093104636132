import { GraphQLClient, gql } from 'graphql-request';
import { Document } from '@contentful/rich-text-types';
import { ContentfulLinksType } from './occasions';

type fetchOffersData = {
    meetingPlacePageOfferCollection: {
        total: number;
        items: OfferType[];
    };
};

export type OfferType = {
    name: string;
    sys: {
        id: string;
    };
    coverImage: {
        sys: {
            id: string;
        };
        title: string;
        url: string;
        size: number;
    };
    tags: string[];
    date: string;
    validFrom: string;
    validTo: string;
    body: {
        json: Document | null;
        links: ContentfulLinksType;
    };
    tenant: {
        sys: {
            id: string;
        };
    };
    typeOfOffer: {
        name: string;
        internalName: string;
    };
};

export type BaseFetchType = {
    client: GraphQLClient;
    locale: string;
    tagId: string;
};

export const fetchMeetingPlaceOffers = async ({ client, locale, tagId, skip = 0 }: BaseFetchType & { skip: number }) => {
    const document = gql`
        query getOffers($locale: String, $tagId: [String], $skip: Int) {
            meetingPlacePageOfferCollection(
                limit: 10
                skip: $skip
                locale: $locale
                where: { contentfulMetadata: { tags: { id_contains_some: $tagId } } }
            ) {
                total
                items {
                    name
                    sys {
                        id
                    }
                    coverImage {
                        sys {
                            id
                        }
                        title
                        url
                        size
                    }
                    date
                    validFrom
                    validTo
                    body {
                        json
                        links {
                            entries {
                                hyperlink {
                                    __typename
                                    sys {
                                        id
                                    }
                                    ... on MeetingPlacePageEditorial {
                                        slug
                                    }
                                    ... on MeetingPlacePageEvent {
                                        slug
                                    }
                                    ... on MeetingPlacePageOffer {
                                        slug
                                    }
                                }
                                block {
                                    __typename
                                    sys {
                                        id
                                    }
                                    ... on BlockLink {
                                        linkText
                                        externalLink
                                    }
                                }
                            }
                            assets {
                                block {
                                    __typename
                                    title
                                    url
                                    sys {
                                        id
                                    }
                                }
                                hyperlink {
                                    __typename
                                    url
                                    title
                                    sys {
                                        id
                                    }
                                }
                            }
                        }
                    }
                    typeOfOffer {
                        name
                        internalName
                    }
                    tenant {
                        ... on MeetingPlacePageTenantShop {
                            sys {
                                id
                            }
                        }
                        ... on MeetingPlacePageTenantEntertainment {
                            sys {
                                id
                            }
                        }
                        ... on MeetingPlacePageTenantFoodBeverage {
                            sys {
                                id
                            }
                        }
                    }
                }
            }
        }
    `;

    const response = await client.request<fetchOffersData>({
        document,
        variables: {
            skip,
            locale,
            tagId: [tagId],
        },
    });

    return response.meetingPlacePageOfferCollection;
};

export const fetchPaginatedOffers = async ({ client, locale, tagId }: BaseFetchType) => {
    let skip = 0;
    let offers: OfferType[] = [];
    let total = -1;

    while (total === -1 || total > offers.length) {
        const collection = await fetchMeetingPlaceOffers({ client, tagId, locale, skip });
        offers = [...offers, ...collection.items];
        skip += collection.items.length;
        total = collection.total && collection.total > 0 ? collection.total : 0;
    }

    return offers;
};
