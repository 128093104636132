import { usePopularSearch } from '../../../hooks/use-popular-search';
import { Place } from '../../../types/visiomap';
import { useLocalization } from '../../../localization/use-localization';

type Props = {
    selectPlace: (floorPlanId: string | null, place: Place | null) => void;
};

export const PopularSearches = ({ selectPlace }: Props) => {
    const { getFormattedPopularSearchTerms } = usePopularSearch();
    const { getTranslation } = useLocalization();

    return (
        <div className="col-start-3 col-end-11">
            <h2 className="my-2 text-xl font-bold">{getTranslation('Popular searches')}</h2>
            <div className="space-y-4 pt-2 text-left text-lg">
                {getFormattedPopularSearchTerms().map((search, index) => (
                    <p className="line-clamp-1 cursor-pointer" key={index} onClick={() => selectPlace(search?.place, null)}>
                        {search.name}
                    </p>
                ))}
            </div>
        </div>
    );
};
