import { useContext, useMemo } from 'react';
import { LangType, MeetingPlaceContext } from '../contexts/meeting-place-provider';
import { ContentPlace, Essential } from '../types/visiomap';
import { getShopName, getPlacesForItem, getPlacesForService } from '../helpers/shops';
import { useLocalization } from '../localization/use-localization';
import { ShopType } from '../services/meeting-place/shops';
import { FoodDrinkType } from '../services/meeting-place/food-drink';
import { singleFloor } from '../helpers/floors';
export type ShopItem = {
    name: string;
    place: ContentPlace | null;
    floor?: string | null;
    imagePath?: string;
};

export type ListItem = {
    title: string;
    items: ShopItem[];
};

export function useShops(visioWebEssential: Essential | null) {
    const { getTranslation, getAlphabet } = useLocalization();
    const { shops, foodDrink, services, lang } = useContext(MeetingPlaceContext);

    const resources = useMemo(() => {
        try {
            return visioWebEssential?._mapviewer?.getExtraData()?.resources;
        } catch (error) {
            console.error(error);
            return null;
        }
    }, [visioWebEssential]);

    const isSingleFloor = useMemo(() => singleFloor(visioWebEssential), [visioWebEssential]);

    const getFloorPlanName = (floorPlanId?: string, lang: LangType | null = 'en') => {
        const invalidResourcesOrFloorPlanId = !resources || !floorPlanId;
        const isOutside = floorPlanId === 'outside';
        if (invalidResourcesOrFloorPlanId || (!isOutside && isSingleFloor)) {
            return null;
        }

        try {
            const floorPlanNames = resources[lang || 'en']?.localized?.locale[lang || 'en']?.venueLayout || null;
            const name = floorPlanNames ? floorPlanNames[floorPlanId] : null;
            return name ? name.name : floorPlanId;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const getAToZlist = () => {
        const allItems = [...shops.items, ...foodDrink.items];
        if (!visioWebEssential?.content?.places) {
            return [];
        }

        const alphabet = getAlphabet();
        const alphabetAndNonAlphabet = alphabet.slice();
        alphabetAndNonAlphabet.unshift('#'); // need to confirm ok to use # as a placeholder for non-alphabetic characters

        const aToZlist = alphabetAndNonAlphabet
            .reduce((acc, letter) => {
                acc.push({
                    title: letter.toLocaleUpperCase(),
                    items: [],
                });

                return acc;
            }, [] as ListItem[])
            .map(({ title }: ListItem) => {
                const places = allItems.reduce((uniqueList, shop) => {
                    if (
                        getShopName(shop)?.charAt(0)?.toLocaleUpperCase() === title &&
                        !uniqueList.some(listItem => listItem.floorPlanIDs?.join('') === shop.floorPlanIDs?.join(''))
                    ) {
                        uniqueList.push(shop);
                    } else if (
                        title === '#' &&
                        !alphabet.some(char => getShopName(shop)?.charAt(0)?.toLocaleUpperCase() === char.toLocaleUpperCase()) &&
                        !uniqueList.some(listItem => listItem.floorPlanIDs?.join('') === shop.floorPlanIDs?.join(''))
                    ) {
                        uniqueList.push(shop);
                    }
                    return uniqueList;
                }, [] as ShopType[]);

                const items = getPlacesForItem(visioWebEssential, places, lang)
                    .filter(({ place }) => !!place?.floor)
                    .map(item => ({
                        ...item,
                        floor: getFloorPlanName(item.place?.floor, lang),
                    }));

                return {
                    title,
                    items,
                };
            });

        return aToZlist;
    };

    const getCategoryList = (allItems: ShopType[] | FoodDrinkType[]) => {
        if (!visioWebEssential?.content?.places) {
            return [];
        }

        const initialList = [
            {
                title: 'empty',
                items: [],
            },
        ];
        return allItems
            .reduce((acc, place) => {
                const primaryCategory = place.primaryCategory?.name ?? place.brand?.primaryCategory?.name;
                if (!primaryCategory) {
                    return acc;
                }

                if (!acc.find(item => item.title === primaryCategory)) {
                    acc.push({
                        title: primaryCategory,
                        items: [],
                    });
                }

                return acc;
            }, initialList)
            .sort((a, b) => (b.title === 'empty' ? -100 : a.title.localeCompare(b.title, lang || 'en'))) // sort 'empty' category last, otherwise alphabetically
            .map(({ title }: ListItem) => {
                const places = allItems.filter(
                    place =>
                        title === 'empty'
                            ? !place.primaryCategory?.name && !place.brand?.primaryCategory?.name
                            : title === (place.primaryCategory?.name ?? place.brand?.primaryCategory?.name) // if primaryCategory is empty, use brand.primaryCategory. Do not remove parentheses, it'll alter the logic
                );

                const items = getPlacesForItem(visioWebEssential, places, lang)
                    .filter(({ place }) => !!place?.floor)
                    .map(item => ({
                        ...item,
                        floor: getFloorPlanName(item.place?.floor, lang),
                    }));

                return {
                    title: title === 'empty' ? getTranslation('OtherString') : title,
                    items,
                };
            })
            .filter(item => item.title && item.items.length);
    };

    const getServices = () => {
        const allItems = [...services.items];
        if (!visioWebEssential?.content?.places) {
            return [];
        }

        const initialList = [
            {
                title: 'empty',
                items: [],
            },
        ];

        return allItems
            .reduce((acc, place) => {
                const primaryCategory = place.serviceType?.serviceCategory?.name;
                if (!primaryCategory) {
                    return acc;
                }

                if (!acc.find(item => item.title === primaryCategory)) {
                    acc.push({
                        title: primaryCategory,
                        items: [],
                    });
                }

                return acc;
            }, initialList)
            .sort((a, b) => (b.title === 'empty' ? -100 : a.title.localeCompare(b.title, lang || 'en'))) // sort 'empty' category last, otherwise alphabetically
            .map(({ title }: ListItem) => {
                const places = allItems.filter(place =>
                    title === 'empty' ? !place.serviceType?.serviceCategory?.name : place.serviceType?.serviceCategory?.name === title
                );

                const items = getPlacesForService(visioWebEssential, places, lang)
                    .filter(({ place }) => !!place?.floor)
                    .map(item => ({
                        ...item,
                        floor: getFloorPlanName(item.place?.floor, lang),
                    }));

                return {
                    title: title === 'empty' ? getTranslation('OtherString') : title,
                    items,
                };
            })
            .filter(item => item.title && item.items.length);
    };

    const getSearchResultListItems = (allItems: ShopType[] | FoodDrinkType[]) => {
        if (!visioWebEssential?.content?.places) {
            return [];
        }

        const items = getPlacesForItem(visioWebEssential, allItems, lang)
            .filter(({ place }) => !!place?.floor)
            .map(item => ({
                ...item,
                floor: getFloorPlanName(item.place?.floor, lang),
            }));

        return items;
    };

    return {
        getAToZlist,
        getServices,
        getCategoryList: () => getCategoryList(shops.items),
        getFoodDrinkList: () => getCategoryList(foodDrink.items),
        getSearchResultListItems,
    };
}
