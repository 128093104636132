import { Document } from '@contentful/rich-text-types';
import { gql } from 'graphql-request';
import { BrandType, OpeningHoursExceptionsCollectionItem, SocialMediaLinks } from './shops';
import { ContentfulLinksType } from './occasions';
import { BaseFetchType } from './offers';

type fetchFoodDrinkData = {
    meetingPlacePageTenantFoodBeverageCollection: {
        total: number;
        items: FoodDrinkType[];
    };
};

export type FoodDrinkType = {
    brand: BrandType | null;
    logo: {
        url: string;
    } | null;
    coverImage: {
        url: string;
    } | null;
    /**
     * This is a list of all the corresponding ids on the map. You can match this with visiowebbessential.content.places
     */
    floorPlanIDs: string[] | null;
    floorPlanId: string; //not sure if this is in use
    name: string | null;
    sys: {
        id: string;
        environmentId: string;
        spaceId: string;
    };
    description: {
        json: Document | null;
        links: ContentfulLinksType;
    } | null;
    openingHours: {
        mondayOpen: string;
        mondayClose: string;
        tuesdayOpen: string;
        tuesdayClose: string;
        wednesdayOpen: string;
        wednesdayClose: string;
        thursdayOpen: string;
        thursdayClose: string;
        fridayOpen: string;
        fridayClose: string;
        saturdayOpen: string;
        saturdayClose: string;
        sundayOpen: string;
        sundayClose: string;
    } | null;
    openingHoursExceptionsCollection: {
        items: OpeningHoursExceptionsCollectionItem[];
    };
    socialMediaLinks: SocialMediaLinks | null;
    primaryCategory: {
        name: string;
    } | null;
    secondaryCategoriesCollection: {
        items: {
            name: string;
        }[];
    };
};

export const fetchMeetingPlaceTenantFoodDrink = async ({
    client,
    tagId,
    locale,
    language,
    skip = 0,
    limit = 8,
}: BaseFetchType & {
    skip?: number;
    limit?: number;
    language: string;
}) => {
    const document = gql`
        query getFoodDrink($skip: Int, $limit: Int, $locale: String, $language: String, $tagId: [String]) {
            meetingPlacePageTenantFoodBeverageCollection(
                skip: $skip
                limit: $limit
                locale: $locale
                where: { contentfulMetadata: { tags: { id_contains_some: $tagId } } }
            ) {
                total
                items {
                    logo {
                        url
                    }
                    coverImage {
                        url
                    }
                    floorPlanIDs
                    floorPlanId
                    name
                    sys {
                        id
                        environmentId
                        spaceId
                    }
                    description {
                        json
                        links {
                            entries {
                                hyperlink {
                                    __typename
                                    sys {
                                        id
                                    }
                                    ... on MeetingPlacePageEditorial {
                                        slug
                                    }
                                    ... on MeetingPlacePageEvent {
                                        slug
                                    }
                                    ... on MeetingPlacePageOffer {
                                        slug
                                    }
                                }
                                block {
                                    __typename
                                    sys {
                                        id
                                    }
                                    ... on BlockLink {
                                        linkText
                                        externalLink
                                    }
                                }
                            }
                            assets {
                                block {
                                    __typename
                                    title
                                    url
                                    sys {
                                        id
                                    }
                                }
                                hyperlink {
                                    __typename
                                    url
                                    title
                                    sys {
                                        id
                                    }
                                }
                            }
                        }
                    }
                    openingHours {
                        mondayOpen
                        mondayClose
                        tuesdayOpen
                        tuesdayClose
                        wednesdayOpen
                        wednesdayClose
                        thursdayOpen
                        thursdayClose
                        fridayOpen
                        fridayClose
                        saturdayOpen
                        saturdayClose
                        sundayOpen
                        sundayClose
                    }
                    socialMediaLinks {
                        webUrl
                        internalName
                        instagramUrl
                        tikTokUrl
                        twitterUrl
                        facebookUrl
                        tripadvisorUrl
                        snapchatUrl
                        whatsAppUrl
                        telegramUrl
                        pinterestUrl
                        vkontakteUrl
                        youTubeUrl
                    }
                    brand {
                        name
                        primaryCategory {
                            name(locale: $language)
                        }
                        secondaryCategoriesCollection {
                            items {
                                name
                            }
                        }
                        description {
                            json
                        }
                        logo {
                            url
                        }
                        coverImage {
                            url
                        }
                    }
                    primaryCategory {
                        name(locale: $language)
                    }
                    secondaryCategoriesCollection {
                        items {
                            name
                        }
                    }
                }
            }
        }
    `;

    const response = await client.request<fetchFoodDrinkData>({
        document,
        variables: {
            skip,
            limit,
            locale,
            language,
            tagId: [tagId],
        },
    });

    return response.meetingPlacePageTenantFoodBeverageCollection;
};

export const fetchPaginatedMeetingPlaceTenantFoodDrink = async ({
    client,
    tagId,
    locale,
    language,
}: BaseFetchType & { language: string }) => {
    let skip = 0;
    let foodDrinks: FoodDrinkType[] = [];
    let total = -1;

    while (total === -1 || total > foodDrinks.length) {
        const collection = await fetchMeetingPlaceTenantFoodDrink({ client, tagId, locale, skip, language });
        foodDrinks = [...foodDrinks, ...collection.items];
        skip += collection.items.length;
        total = collection.total && collection.total > 0 ? collection.total : 0;
    }

    return foodDrinks;
};
