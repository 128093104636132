import { gql } from 'graphql-request';
import { BaseFetchType } from './offers';

type fetchFeedbackData = {
    meetingPlacePageHomeCollection: {
        items: FeedbackItemType[];
    };
};

export type FeedbackItemType = {
    internalName: string;
    footer: {
        feedbackUrlWayfinder: string;
    };
};

export const fetchMeetingPlaceFeedback = async ({ client, tagId, locale }: BaseFetchType) => {
    const document = gql`
        query getFeedback($locale: String, $tagId: [String]) {
            meetingPlacePageHomeCollection(locale: $locale, where: { contentfulMetadata: { tags: { id_contains_some: $tagId } } }) {
                items {
                    internalName
                    footer {
                        ... on BlockFooter {
                            feedbackUrlWayfinder
                        }
                    }
                }
            }
        }
    `;

    const response = await client.request<fetchFeedbackData>({
        document,
        variables: {
            locale,
            tagId: [tagId],
        },
    });

    return response.meetingPlacePageHomeCollection.items || [];
};
