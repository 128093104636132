import { useContext, useMemo } from 'react';
import { MeetingPlaceContext } from '../../../contexts/meeting-place-provider';
import { useFindPlace } from '../../../hooks/use-find-place';
import { Offer } from '../../offers/offer';

type Props = {
    activeFloorPlanId: string;
};

export const Offers = ({ activeFloorPlanId }: Props) => {
    const { offers } = useContext(MeetingPlaceContext);
    const place = useFindPlace(activeFloorPlanId);

    const activeOffers = useMemo(() => {
        if (!place) {
            return [];
        }
        return offers.items.filter(offer => {
            if (offer.tenant?.sys?.id !== place?.sys?.id) {
                return false;
            }

            return new Date(offer.validTo) >= new Date();
        });
    }, [place, offers]);

    return (
        <div data-component="offers">
            {activeOffers.map((offer, index) => (
                <div key={index} className="pb-6 last:pb-0">
                    <Offer offer={offer} />
                </div>
            ))}
        </div>
    );
};
