import { isPast } from 'date-fns';
import { FoodDrinkType } from '../services/meeting-place/food-drink';
import { OccasionType } from '../services/meeting-place/occasions';
import { OfferType } from '../services/meeting-place/offers';
import { ServiceType } from '../services/meeting-place/services';
import { ShopType } from '../services/meeting-place/shops';
import { WayfinderSettingsData } from '../services/meeting-place/wayfinder';

const images = new Set<string>([]);

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function serviceWorkerCache({
    offers,
    occasions,
    shops,
    foodDrink,
    services,
    settings,
}: {
    offers: OfferType[];
    occasions: OccasionType[];
    shops: ShopType[];
    foodDrink: FoodDrinkType[];
    services: ServiceType[];
    settings?: WayfinderSettingsData | null;
}) {
    if (window?.location?.hostname?.includes('localhost') || window?.location?.hostname?.includes('netlify')) {
        return;
    }

    await cacheOffers(offers);
    await sleep(1000);

    await cacheOccasions(occasions);
    await sleep(1000);

    await cacheShops(shops);
    await sleep(1000);

    await cacheFoodDrink(foodDrink);
    await sleep(1000);

    await cacheServices(services);
    await sleep(1000);

    await cacheSettings(settings);
    await sleep(1000);
}

async function cacheOffers(items: OfferType[]) {
    items.forEach(async item => await cacheFetch(item.coverImage?.url));
}

async function cacheOccasions(items: OccasionType[]) {
    items.filter(occasion => !isPast(occasion.endDate)).forEach(async item => await cacheFetch(item.coverImage?.url));
}

async function cacheShops(items: ShopType[]) {
    items.forEach(async item => await cacheAssets(item));
}

async function cacheFoodDrink(items: FoodDrinkType[]) {
    items.forEach(async item => await cacheAssets(item));
}

async function cacheServices(items: ServiceType[]) {
    items.forEach(async item => {
        await cacheFetch(item.serviceType?.coverImage?.url);
        await cacheFetch(item.serviceType?.floorPlanIcon?.url);
    });
}

async function cacheSettings(settings?: WayfinderSettingsData | null) {
    if (!settings) {
        return;
    }

    await cacheFetch(settings.wayfinderSettings?.loadingScreen?.url);

    settings.wayfinderSettings?.informationMenuCollection?.items?.forEach(async item => await cacheFetch(item?.image?.url));
}

async function cacheFetch(imageUrl?: string | null) {
    if (imageUrl && !images.has(imageUrl)) {
        try {
            images.add(imageUrl);
            await fetch(imageUrl);
        } catch (error) {
            console.error(error);
        }
    }
}

async function cacheAssets(item: ShopType | FoodDrinkType) {
    await cacheFetch(item.coverImage?.url);
    await cacheFetch(item.logo?.url);
    await cacheFetch(item.brand?.logo?.url);
    await cacheFetch(item.brand?.coverImage?.url);
}
