import { useContext } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import { CmsText } from '../cms-text';
import { Layout } from '../layout/layout';

export const Information = () => {
    const { settings } = useContext(MeetingPlaceContext);

    return (
        <Layout showMapLink data-component="information">
            {settings.settings?.wayfinderSettings?.informationMenuCollection?.items?.map((item, index) => (
                <div key={index} className="grid grid-cols-2 gap-8 pb-8">
                    <div>{item?.image?.url ? <img src={item.image.url} className="rounded" /> : null}</div>
                    <div className="space-y-8 rounded bg-beige px-12 py-20">
                        <h1 className="text-4xl font-bold">{item?.name}</h1>
                        <CmsText json={item?.body.json} />
                    </div>
                </div>
            ))}
        </Layout>
    );
};
