import clsx, { ClassValue } from 'clsx';
import { Link } from 'react-router-dom';
import { useLocalization } from '../../localization/use-localization';
import { OfferType } from '../../services/meeting-place/offers';
import { ChevronRight } from '../../assets/svg/chevron-right';
import { useOffers, TypeOfOffer } from '../../hooks/use-offers';

type Props = {
    offer: OfferType;
    classNames?: ClassValue;
    size?: 'small' | 'large' | 'xs';
};

export const OfferSmall = ({ offer }: Props) => {
    const { getTranslation, formatDate } = useLocalization();
    const { getTenantForOffer, formatTypeOfOffer } = useOffers();
    const shop = getTenantForOffer(offer);
    const offerType = formatTypeOfOffer(offer.typeOfOffer.internalName);
    return (
        <div>
            <div className="flex h-full w-full flex-col justify-between overflow-hidden rounded">
                <div className="flex flex-1 flex-col overflow-hidden bg-beige px-[10%] pb-2 pt-4">
                    <div className="mt-3">
                        {shop?.logo?.url || shop?.brand?.logo?.url ? (
                            <img className="mb-2 w-16 rounded-md bg-white" src={shop?.logo?.url || shop?.brand?.logo?.url} />
                        ) : (
                            <div className="mb-2 h-16 w-16" />
                        )}
                    </div>
                    <div className="mt-7">
                        {shop?.brand ? <h3 className="truncate text-lg">{shop.brand.name}</h3> : <div className="h-7 w-2"></div>}
                        <h2 className="truncate text-lg font-semibold">{offer.name}</h2>
                        <div
                            className={clsx('font-bold', {
                                'text-ikea-family-offers': offerType === TypeOfOffer.ikeaFamilyMemberOffer,
                                'text-special-offers': offerType === TypeOfOffer.specialOffer,
                            })}
                        >
                            {offerType !== TypeOfOffer.regularOffer && offer.typeOfOffer.name}
                        </div>
                        <p className="truncate">
                            {getTranslation('Valid until')} {formatDate(offer.validTo)}
                        </p>
                    </div>
                    <div className="h-11"></div>
                </div>
                <div className="relative flex h-8 items-center justify-between overflow-hidden">
                    <div className="absolute inset-x-6 h-8 bg-beige">
                        <div className="absolute inset-x-2 top-3 h-8 border-t-8 border-dotted border-white" />
                    </div>
                    <div className="h-16 w-16 flex-initial -translate-x-1/2 rounded-full border-[1rem] border-beige bg-transparent bg-cover bg-repeat" />
                    <div className="h-16 w-16 flex-initial translate-x-1/2 rounded-full border-[1rem] border-beige bg-transparent bg-cover bg-repeat" />
                </div>
                <div>
                    <Link
                        to={`/offers/${offer.sys.id}`}
                        className="flex items-center justify-between rounded-b bg-beige p-[9%] pb-[10%] font-bold"
                        data-tracking="view-offer-btn"
                    >
                        <span>{getTranslation('View offer')}</span>
                        <span className="h-6 w-6">
                            <ChevronRight />
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};
