import { LangType } from '../../contexts/meeting-place-provider';
import { navigationTranslator } from '../../localization/navigation-translator';
import { Essential } from '../../types/visiomap';

export const setLanguage = (language: LangType, visioWebEssential: Essential) => {
    if (!visioWebEssential.navigation?.translator) {
        return;
    }

    try {
        const translation = navigationTranslator(language);
        visioWebEssential!.navigation!.translator.addOrUpdateLanguage(language || '', JSON.stringify(translation));
        visioWebEssential!.navigation!.setLanguageCode({
            languageCode: language,
        });
        const allFloors = visioWebEssential!._mapviewer!.getExtraData().resources[language].localized.locale[language].venueLayout;

        visioWebEssential!.navigation.translator.setFloors(allFloors);
    } catch (error) {
        console.log(`Failed to set language. Tried languauge: "${language}". Error: `, error);
    }
};
