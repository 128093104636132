import { useState } from 'react';
import { CameraPoint, Essential, Floor } from '../../types/visiomap';
import { UpdateFloorFunction } from './visiomap';

export type RecenterMapFunction = (
    visioWebEssential: Essential,
    updateFloor: UpdateFloorFunction,
    setActiveInstructionIndex: React.Dispatch<React.SetStateAction<number>>
) => void;

export const useRecenter = () => {
    const [defaultCameraPosition, setDefaultCameraPosition] = useState<CameraPoint>({ x: 0, y: 0, radius: 0 });
    const [currentNavCameraPosition, setCurrentNavCameraPosition] = useState<CameraPoint | null>(null);
    const [kioskFloor, setKioskFloor] = useState<Floor | null>(null);
    const recenterMap: RecenterMapFunction = (visioWebEssential, updateFloor, setActiveInstructionIndex) => {
        if (!visioWebEssential?._mapviewer || !kioskFloor) {
            return;
        }

        if (currentNavCameraPosition && visioWebEssential.navigation?.currentInstructionIndex) {
            while (visioWebEssential.navigation.currentInstructionIndex > 0) {
                visioWebEssential.navigation?.goToPreviousInstruction();
            }
            setActiveInstructionIndex(0);
        }

        const positionToUse = currentNavCameraPosition || defaultCameraPosition;

        visioWebEssential._mapviewer?.multiBuildingView.goTo({
            viewpoint: { position: positionToUse },
        });
        updateFloor(kioskFloor);
    };

    return { setDefaultCameraPosition, recenterMap, setKioskFloor, setCurrentNavCameraPosition };
};
