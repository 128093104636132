import React from 'react';
import { useLocalization } from '../../localization/use-localization';
import clsx from 'clsx';
import { IoBackspaceOutline } from 'react-icons/io5';

type KeyboardProps = {
    onChange?: (input: string) => void;
    onKeyPress: (button: string) => void;
    mode?: 'letters' | 'digits';
};

export const Keyboard: React.FC<KeyboardProps> = ({ onKeyPress, mode = 'letters' }) => {
    const { getAlphabet } = useLocalization();
    const alphabet = getAlphabet();
    const digits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    const buttonBaseClass = clsx('rounded-sm border-[1px] font-sans text-3xl capitalize', {
        'min-h-[48px] min-w-[48px]': window?.innerWidth > 1600,
    });

    return (
        <div
            className={clsx('grid w-full gap-2 p-4', {
                'grid-cols-6': mode === 'letters',
                'grid-cols-3': mode === 'digits',
                'rounded-t-2xl shadow-sidebar': true,
            })}
        >
            {mode === 'letters'
                ? alphabet.map((letter, index) => {
                      return (
                          <button className={clsx(buttonBaseClass)} key={index} onClick={() => onKeyPress(letter)}>
                              {letter}
                          </button>
                      );
                  })
                : digits.map((digit, index) => {
                      return (
                          <button className={clsx(buttonBaseClass)} key={index} onClick={() => onKeyPress(digit)}>
                              {digit}
                          </button>
                      );
                  })}
            <div className="col-span-full"></div> {/* For forcing next set of buttons to new row */}
            <button
                className={clsx(buttonBaseClass, {
                    'col-span-2': mode === 'letters',
                    'col-span-1': mode === 'digits',
                })}
                onClick={() => onKeyPress('{toggleMode}')}
            >
                {mode === 'letters' ? '1 - 9' : 'A - Z'}
            </button>
            <button
                className={clsx(buttonBaseClass, 'flex', {
                    'col-span-4': mode === 'letters',
                    'col-span-2': mode === 'digits',
                })}
                onClick={() => onKeyPress('{bksp}')}
            >
                <IoBackspaceOutline size={'2.5rem'} className="m-auto" />
            </button>
        </div>
    );
};
