export const Information = () => (
    <svg viewBox="0 0 76 73" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Information">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65.8205 34.0266C64.9919 34.0266 64.1974 34.3427 63.6116 34.9053C63.0257 35.4679 62.6966 36.2309 62.6966 37.0266C62.6979 42.5983 60.6805 47.9969 56.9905 52.2965C53.3004 56.596 48.1677 59.5286 42.4724 60.5914C36.7771 61.6541 30.8742 60.7807 25.7761 58.1211C20.678 55.4614 16.7024 51.1813 14.531 46.0145C12.3595 40.8477 12.1276 35.1164 13.8751 29.8035C15.6225 24.4905 19.2405 19.9268 24.1083 16.8952C28.9762 13.8637 34.7907 12.5533 40.5547 13.1885C46.3186 13.8236 51.6728 16.365 55.6991 20.3766C55.9895 20.6578 56.3351 20.8809 56.7157 21.0332C57.0964 21.1856 57.5047 21.264 57.9171 21.264C58.3295 21.264 58.7378 21.1856 59.1185 21.0332C59.4992 20.8809 59.8447 20.6578 60.1352 20.3766C60.717 19.8145 61.0435 19.0541 61.0435 18.2616C61.0435 17.469 60.717 16.7088 60.1352 16.1467C57.2348 13.224 53.7443 10.8999 49.8771 9.31633C46.0099 7.73279 41.8472 6.92312 37.6432 6.93669C31.4647 6.93669 25.4249 8.69616 20.2877 11.9926C15.1506 15.289 11.1466 19.9744 8.78216 25.4562C6.41777 30.9379 5.79922 36.9699 7.00458 42.7893C8.20993 48.6087 11.1851 53.9543 15.5539 58.1498C19.9227 62.3454 25.489 65.2027 31.5487 66.3603C37.6084 67.5178 43.8896 66.9236 49.5978 64.653C55.3059 62.3824 60.1847 58.5372 63.6173 53.6037C67.0499 48.6702 68.882 42.8701 68.882 36.9367C68.8581 36.167 68.5269 35.4357 67.9571 34.894C67.3872 34.3523 66.6221 34.0417 65.8205 34.0266ZM37.7055 22.297C36.943 22.2911 36.1958 22.5032 35.5592 22.9064C34.9226 23.3095 34.4252 23.8854 34.1305 24.5609C33.8358 25.2364 33.7571 25.9808 33.9044 26.6994C34.0517 27.418 34.4182 28.0782 34.9574 28.596C35.4967 29.1139 36.1843 29.4659 36.9326 29.6073C37.6808 29.7487 38.456 29.6731 39.1594 29.3901C39.8627 29.1071 40.4624 28.6295 40.8822 28.0181C41.302 27.4067 41.5229 26.6892 41.5168 25.9569C41.5168 24.9862 41.1151 24.0553 40.4004 23.3689C39.6857 22.6825 38.7163 22.297 37.7055 22.297ZM34.5818 34.3569H40.8295V52.267H34.5818V34.3569Z"
                fill="#474747"
            />
        </g>
    </svg>
);
