import clsx from 'clsx';
import { useLocalization } from '../../../localization/use-localization';
import { PopularSearches } from './popular-searches';
import { Place } from '../../../types/visiomap';
import { ViewType } from './right-side-panel';
import { Search } from '../../../assets/svg/search';

type Props = {
    setView: (view: ViewType) => void;
    selectPlace: (floorPlanId: string | null, place: Place | null) => void;
};

export const RightLanding = ({ setView, selectPlace }: Props) => {
    const { getTranslation } = useLocalization();

    return (
        <div data-component="right-landing">
            <div className="h-full overflow-scroll">
                <div className="flex w-full items-center justify-center justify-items-center pb-12 xl:pb-24">
                    <button
                        role="button"
                        className="h-44 w-44 xl:h-52 xl:w-52"
                        onClick={() => {
                            setView('search');
                        }}
                        data-tracking="search-btn"
                    >
                        <Search />
                    </button>
                </div>

                <div className={clsx('relative px-12 transition-opacity duration-100')}>
                    <PopularSearches selectPlace={selectPlace} />

                    <div className="col-start-3 col-end-11 mt-12">
                        <h2 className="my-2 text-xl font-bold">{getTranslation('Search by')}</h2>
                        <div className="flex flex-col items-start gap-4 pt-2 text-lg">
                            <button className="cursor-pointer" onClick={() => setView('A-Z')} data-tracking="open-a-z-btn">
                                {getTranslation('A-Z')}
                            </button>
                            <button className="cursor-pointer" onClick={() => setView('categories')} data-tracking="open-categories-btn">
                                {getTranslation('Categories')}
                            </button>
                            <button className="cursor-pointer" onClick={() => setView('restaurants')} data-tracking="open-restaurants-btn">
                                {getTranslation('Food & Beverage')}
                            </button>
                            <button className="cursor-pointer" onClick={() => setView('services')} data-tracking="open-services-btn">
                                {getTranslation('Services')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
