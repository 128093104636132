import clsx from 'clsx';
import QRCode from 'react-qr-code';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Close } from '../../assets/svg/close';
import { useLocalization } from '../../localization/use-localization';

type Props = {
    text?: React.ReactNode | string;
    uri?: string;
    isButton?: boolean;
};

export const QrCode = ({ text, uri, isButton = false }: Props) => {
    const { getTranslation } = useLocalization();
    const containerRef = useRef<HTMLSpanElement>(null);
    const [isCodeVisible, setIsCodeVisible] = useState(false);

    useEffect(() => {
        const handleClick = (e: Event | MouseEvent) => {
            if (e.target && !containerRef?.current?.contains(e.target as Node)) {
                setIsCodeVisible(false);
            }
        };

        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);

    const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setIsCodeVisible(true);
    };

    if (!text || !uri) {
        return null;
    }

    return (
        <span data-component="qr-code" ref={containerRef}>
            <a
                target="_blank"
                href={uri}
                onClick={onClick}
                data-tracking="show-qr-code"
                className={clsx({
                    'inline-block rounded-full bg-accent px-6 py-3.5 font-bold no-underline': isButton,
                })}
            >
                {text}
            </a>
            <span
                className={clsx(
                    'absolute left-[calc(50%-8rem)] -mt-32 grid w-64 place-items-center rounded-2xl bg-white px-4 pb-8 pt-14 shadow-sidebar',
                    isCodeVisible ? 'absolute z-10' : 'hidden'
                )}
            >
                <button
                    onClick={() => setIsCodeVisible(false)}
                    className="absolute right-2 top-2 grid h-10 w-10 cursor-pointer place-items-center"
                    data-tracking="qr-code-close"
                >
                    <Close fill="none" />
                </button>
                <span className="block w-32 pb-6">
                    <QRCode value={uri} className="h-32 w-32" />
                </span>
                <span className="block text-center leading-snug text-gray-800">{getTranslation('Scan QR-Code to go to link')}</span>
            </span>
        </span>
    );
};
