import { useContext } from 'react';
import { isPast, isFuture } from 'date-fns';
import { OccasionType } from '../services/meeting-place/occasions';
import { MeetingPlaceContext } from '../contexts/meeting-place-provider';

export const useEvents = () => {
    const { occasions } = useContext(MeetingPlaceContext);

    const getActiveEvents = () => {
        const futureDates: OccasionType[] = [];
        const pastDates: OccasionType[] = [];

        occasions.items
            .filter(occasion => !isPast(occasion.endDate))
            .forEach(event => {
                if (isFuture(event.startDate)) {
                    futureDates.push(event);
                    return;
                }
                pastDates.push(event);
            });

        futureDates.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
        pastDates.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

        return [...futureDates, ...pastDates];
    };

    const getEventById = (id?: string) => (id ? occasions.items.find(({ sys }) => sys.id === id) : null);

    return {
        getActiveEvents,
        getEventById,
    };
};
