import { gql } from 'graphql-request';
import { Document } from '@contentful/rich-text-types';
import { BaseFetchType } from './offers';

type fetchOccasionsData = {
    libraryMeetingPlace: {
        occationListPage: {
            occasionsCollection: {
                items: OccasionType[];
            };
        };
    };
};

type fetchOccasionsDataNew = {
    libraryMeetingPlace: {
        eventListPage: {
            eventItemsCollection: {
                total: number;
                items: OccasionType[];
            };
        };
    };
};

export type ContentfulLinksType = {
    assets: {
        block: {
            __typename: string;
            sys: {
                id: string;
            };
            url: string;
            title: string;
        }[];
        hyperlink: {
            __typename: string;
            sys: {
                id: string;
            };
            url: string;
            title: string;
        }[];
    };
    entries: {
        hyperlink: {
            __typename: string;
            sys: {
                id: string;
            };
            slug: string;
        }[];
        block: {
            __typename: string;
            sys: {
                id: string;
            };
            linkText: string;
            externalLink: string;
            internalLink: {
                __typename: string;
                slug: string;
            };
        }[];
    };
};

export type OccasionType = {
    name: string;
    coverImage: {
        url: string;
    };
    floorPlanId: string;
    eventTypesCollection: {
        items: {
            name: string;
        }[];
    };
    body: {
        json: Document | null;
        links: ContentfulLinksType;
    };
    startDate: string;
    endDate: string;
    hideInListFrom: string;
    sys: {
        id: string;
        environmentId: string;
        spaceId: string;
    };
};

export type OccasionsFetchType = Omit<BaseFetchType, 'tagId'> & {
    meetingPlaceId: string;
};

const fetchEventListPage = async ({
    client,
    locale,
    meetingPlaceId,
    skip = 0,
}: OccasionsFetchType & {
    skip: number;
}) => {
    const document = gql`
        query getOccasions($id: String!, $locale: String, $date: DateTime, $skip: Int) {
            libraryMeetingPlace(id: $id, locale: $locale) {
                eventListPage {
                    eventItemsCollection(limit: 10, skip: $skip, where: { endDate_gte: $date, AND: { sys: { id_exists: true } } }) {
                        total
                        items {
                            name
                            coverImage {
                                url
                            }
                            eventTypesCollection {
                                items {
                                    name
                                }
                            }
                            sys {
                                id
                                environmentId
                                spaceId
                            }
                            startDate
                            endDate
                            body {
                                json
                                links {
                                    entries {
                                        hyperlink {
                                            __typename
                                            sys {
                                                id
                                            }
                                            ... on MeetingPlacePageEditorial {
                                                slug
                                            }
                                            ... on MeetingPlacePageEvent {
                                                slug
                                            }
                                            ... on MeetingPlacePageOffer {
                                                slug
                                            }
                                        }
                                        block {
                                            __typename
                                            sys {
                                                id
                                            }
                                            ... on BlockLink {
                                                linkText
                                                externalLink
                                            }
                                        }
                                    }
                                    assets {
                                        block {
                                            __typename
                                            title
                                            url
                                            sys {
                                                id
                                            }
                                        }
                                        hyperlink {
                                            __typename
                                            url
                                            title
                                            sys {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const response = await client.request<fetchOccasionsDataNew>({
        document,
        variables: {
            skip,
            locale,
            id: meetingPlaceId,
            date: new Date().toISOString(),
        },
    });

    return response.libraryMeetingPlace.eventListPage.eventItemsCollection;
};

export const fetchPaginatedEvents = async ({ client, meetingPlaceId, locale }: OccasionsFetchType) => {
    let skip = 0;
    let events: OccasionType[] = [];
    let total = -1;

    while (total === -1 || total > events.length) {
        const collection = await fetchEventListPage({ client, meetingPlaceId, locale, skip });
        events = [...events, ...collection.items];
        skip += collection.items.length;
        total = collection.total && collection.total > 0 ? collection.total : 0;
    }

    return events;
};

const fetchLegacyEvents = async ({
    client,
    locale,
    meetingPlaceId,
}: Omit<BaseFetchType, 'tagId'> & {
    meetingPlaceId: string;
}) => {
    const document = gql`
        query getOccasions($id: String!, $locale: String) {
            libraryMeetingPlace(id: $id, locale: $locale) {
                occationListPage {
                    # This where clause is to avoid errors, not 100% sure this
                    # will give us the data we need... @richard
                    occasionsCollection(where: { sys: { id_exists: true } }, limit: 500) {
                        items {
                            ... on MeetingPlacePageEvent {
                                name
                                coverImage {
                                    url
                                }
                                eventTypesCollection {
                                    items {
                                        name
                                    }
                                }
                                body {
                                    json
                                }
                                startDate
                                endDate
                                hideInListFrom
                                sys {
                                    id
                                    environmentId
                                    spaceId
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const response = await client.request<fetchOccasionsData>({
        document,
        variables: {
            locale,
            id: meetingPlaceId,
        },
    });

    return response.libraryMeetingPlace.occationListPage.occasionsCollection.items || [];
};

// This is the OLD API, it's here for legacy reasons only
export const fetchMeetingPlaceOccasions = async ({
    client,
    locale,
    meetingPlaceId,
}: Omit<BaseFetchType, 'tagId'> & {
    meetingPlaceId: string;
}) => {
    const legacyEvents = await fetchLegacyEvents({ client, locale, meetingPlaceId });
    const newEvents = await fetchPaginatedEvents({ client, locale, meetingPlaceId });

    legacyEvents.forEach(event => {
        const newEventExits = !!newEvents.find(e => e.sys.id === event.sys.id);
        // Check for duplicates
        if (!newEventExits) {
            newEvents.push(event);
        }
    });

    return newEvents;
};
