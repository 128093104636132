import React, { TouchEventHandler } from 'react';
import { Recenter } from '../../../assets/svg/recenter';

type Props = {
    onRecenter: () => void;
    onTouchStart: TouchEventHandler<HTMLButtonElement>;
    onTouchEnd: TouchEventHandler<HTMLButtonElement>;
};

export const RecenterControls: React.FC<Props> = ({ onRecenter: onRecenter, onTouchEnd, onTouchStart }) => (
    <div className="space-y-3 pt-6">
        <button
            className="rounded-button"
            onTouchEnd={e => {
                onTouchEnd(e);
                onRecenter();
            }}
            onTouchStart={onTouchStart}
        >
            <Recenter className="h-6 overflow-visible" offset={{ x: -4, y: 4 }} />
        </button>
    </div>
);
