import { gql } from 'graphql-request';
import { BaseFetchType } from './offers';

export type LibraryUrlSubdirectoryItem = {
    urlSubdirectory: string;
    contentTypes: string[];
};

type Response = {
    libraryUrlSubdirectoryCollection: {
        items: LibraryUrlSubdirectoryItem[];
    };
};

export const fetchLibraryUrlSubdirectoryCollection = async ({ client, locale }: BaseFetchType) => {
    const document = gql`
        query libraryUrlSubdirectoryCollection($locale: String) {
            libraryUrlSubdirectoryCollection(locale: $locale) {
                items {
                    urlSubdirectory
                    contentTypes
                }
            }
        }
    `;

    const response = await client.request<Response>({
        document,
        variables: {
            locale,
        },
    });

    return response.libraryUrlSubdirectoryCollection.items;
};
