import { getDay } from 'date-fns';
import { useLocalization } from '../../../localization/use-localization';
import { ShopType } from '../../../services/meeting-place/shops';

export const OpenToday = ({ place }: { place: ShopType }) => {
    const { getTranslation } = useLocalization();

    const closingTimeToday = () => {
        const day = getDay(new Date());

        const closingTimes = [
            place.openingHours?.sundayClose,
            place.openingHours?.mondayClose,
            place.openingHours?.tuesdayClose,
            place.openingHours?.wednesdayClose,
            place.openingHours?.thursdayClose,
            place.openingHours?.fridayClose,
            place.openingHours?.saturdayClose,
        ];

        return closingTimes[day] || null;
    };

    const closingTime = closingTimeToday();

    if (!place?.openingHours || !closingTime) {
        return null;
    }

    return (
        <p className="mb-8">
            {closingTime === 'Closed' ? getTranslation('Closed') : `${getTranslation('Open today until')} ${closingTime}`}
        </p>
    );
};
