import { GraphQLClient, gql } from 'graphql-request';

type fetchMapSlugData = {
    libraryMeetingPlace: {
        visioGlobeFloorPlanPage: {
            slug: string;
        };
    };
};

export const fetchMapSlug = async ({
    client,
    meetingPlaceId,
    locale,
}: {
    client: GraphQLClient;
    meetingPlaceId: string;
    locale: string;
}) => {
    const document = gql`
        query getMapHash($id: String!, $locale: String!) {
            libraryMeetingPlace(id: $id) {
                visioGlobeFloorPlanPage {
                    slug(locale: $locale)
                }
            }
        }
    `;

    const response = await client.request<fetchMapSlugData>({
        document,
        variables: {
            id: meetingPlaceId,
            locale,
        },
    });

    return response.libraryMeetingPlace.visioGlobeFloorPlanPage.slug;
};
