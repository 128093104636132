import clsx from 'clsx';

export const TagsSmall = ({ tags = [] }: { tags?: { name: string }[] }) => {
    if (!Array.isArray(tags) || !tags?.length) {
        return null;
    }
    return (
        <div className="flex h-full flex-col justify-between pb-4">
            <div data-component="tags" className="flex max-h-full max-w-full flex-wrap items-start justify-start gap-2  pt-2">
                {tags.map(({ name }, index) => (
                    <div
                        key={index}
                        className={clsx('rounded-[4px] border-2 border-[#adaaa3] px-2 text-xs', {
                            'border-[#8EBDA8] bg-[#C0D9CA]': index === 0,
                        })}
                    >
                        {name}
                    </div>
                ))}
            </div>
        </div>
    );
};
