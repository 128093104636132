import QRCode from 'react-qr-code';
import { OfferType } from '../../services/meeting-place/offers';
import { OccasionType } from '../../services/meeting-place/occasions';
import { OfferSmall } from './offer-small';
import { EventSmall } from './event-small';
import { useLocalization } from '../../localization/use-localization';
import { NavLink } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import clsx from 'clsx';

type OEProps = {
    offers: OfferType[];
    events: OccasionType[];
    showEvents: boolean | null | undefined;
    showOffers: boolean | null | undefined;
};

const OffersEventsSection = (props: OEProps) => {
    const { events, offers, showEvents, showOffers } = props;
    const { getTranslation } = useLocalization();
    /* const { libraryUrlSubdirectoryCollection, locale, domain } = useContext(MeetingPlaceContext); */
    const { libraryUrlSubdirectoryCollection, locale, domain } = useContext(MeetingPlaceContext);
    const [offerQRUrl, setOfferQRUrl] = useState('');
    const [eventQRUrl, setEventQRUrl] = useState('');
    const [qrSize, setQrSize] = useState(80);

    useEffect(() => {
        libraryUrlSubdirectoryCollection.items.forEach(el => {
            if (el.contentTypes.indexOf('ContentfulMeetingPlacePageOfferItem') >= 0) {
                setOfferQRUrl(`${domain}/${locale}/${el.urlSubdirectory}`);
            }
            if (el.contentTypes.indexOf('ContentfulMeetingPlacePageEventItem') >= 0) {
                setEventQRUrl(`${domain}/${locale}/${el.urlSubdirectory}`);
            }
        });
        handleResize();
    }, []);

    const handleResize = () => {
        if (window.innerHeight > 900 && window.innerHeight <= 1080) {
            setQrSize(90);
        } else if (window.innerHeight > 1080 && window.innerHeight <= 1620) {
            setQrSize(100);
        } else if (window.innerHeight > 1620 && window.innerHeight <= 2000) {
            setQrSize(150);
        } else if (window.innerHeight > 2000) {
            setQrSize(180);
        } else {
            setQrSize(80);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="flex h-full w-full flex-row gap-5">
            {/* {events.length > 0 ? ( */}
            {events.length > 0 && showEvents ? (
                <div className="h-full w-full flex-1 flex-col gap-3">
                    <div className="flex h-[15%] flex-row gap-8 rounded bg-[#F7F4EF]">
                        <div className="w-[60%] flex-shrink-0 flex-row p-5">
                            <div className="mb-3 text-xl font-bold">{getTranslation('Events')}</div>
                            <div>{getTranslation('ScanQrCodeEvents')}</div>
                        </div>
                        <div className="flex h-full w-[30%] flex-shrink-0 items-center justify-center">
                            <QRCode value={eventQRUrl} size={qrSize} color="#F7F4EF" />
                        </div>
                    </div>
                    <div
                        className={clsx('mt-4 grid gap-4', {
                            'grid-cols-2 grid-rows-1': events.length < 3,
                            'grid-cols-2 grid-rows-2': events.length >= 3,
                        })}
                    >
                        {events.map((el, i) => {
                            if (i > 3) {
                                return null;
                            }
                            return (
                                <div key={i} className="max-h-full rounded bg-[#F7F4EF]">
                                    <EventSmall event={el as OccasionType} classNames="h-auto max-w-full" key={i} size="xs" />
                                </div>
                            );
                        })}
                    </div>
                    <div className="mt-2 flex items-center justify-start">
                        <NavLink to="/events" className="mt-3 rounded bg-[#EAE3DA] p-[2%] px-4" data-tracking="open-offers-btn">
                            <div>{getTranslation('seeAllEvents')}</div>
                        </NavLink>
                    </div>
                </div>
            ) : null}
            {/* {offers.length > 0 ? ( */}
            {offers.length && showOffers ? (
                <div className="h-full w-full flex-1 flex-col gap-3">
                    <div className="flex h-[15%] flex-row gap-8 rounded bg-[#F7F4EF]">
                        <div className="w-[60%] flex-shrink-0 flex-row p-5">
                            <div className="mb-3 text-xl font-bold">{getTranslation('Offers')}</div>
                            <div>{getTranslation('ScanQrCodeOffers')}</div>
                        </div>
                        <div className="flex h-full w-[30%] flex-shrink-0 items-center justify-center">
                            <QRCode value={offerQRUrl} size={qrSize} color="#F7F4EF" />
                        </div>
                    </div>
                    <div
                        className={clsx('mt-4 grid gap-4', {
                            'grid-cols-2 grid-rows-1': events.length < 3,
                            'grid-cols-2 grid-rows-2': events.length >= 3,
                        })}
                    >
                        {offers.map((el, i) => {
                            if (i > 3) {
                                return null;
                            }
                            return (
                                <div key={i} className="max-h-full rounded ">
                                    <OfferSmall key={el.name} offer={el} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="mt-2 flex items-center justify-start">
                        <NavLink to="/offers" className="mt-3 rounded bg-[#EAE3DA] p-[2%] px-4" data-tracking="open-offers-btn">
                            <div>{getTranslation('seeAllOffers')}</div>
                        </NavLink>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default OffersEventsSection;
