import { useContext, useEffect, useRef, useState } from 'react';
import { gql } from 'graphql-request';
import { IoCloudOffline } from 'react-icons/io5';
import { MeetingPlaceContext } from './contexts/meeting-place-provider';
import { FetchLocaleData } from './services/meeting-place/meeting-place-data';
import { usePopularSearch } from './hooks/use-popular-search';

const HOUR_IN_MS = 60 * 60 * 1000;

export const Cron = () => {
    const { client, meetingPlaceId } = useContext(MeetingPlaceContext);
    const [offline, setOffline] = useState(false);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const cronHour = useRef<number>(23);
    const { resetPopularSearch } = usePopularSearch();
    useEffect(() => {
        if (!meetingPlaceId) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('restartHour')) {
            cronHour.current = parseInt(urlParams.get('restartHour') as string);
        }

        timer.current = setInterval(runCron, HOUR_IN_MS);

        return () => {
            if (timer.current) {
                clearInterval(timer.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingPlaceId]);

    const runCron = async () => {
        const currentHour = new Date().getHours();
        if (currentHour !== cronHour.current) {
            return;
        }

        // Do we need this?
        if (!window.navigator.onLine) {
            setOffline(true);
            console.error('Error, window.navigator.onLine is false');
            return;
        }

        const canDoRequest = await doTestFetch();
        if (!canDoRequest) {
            setOffline(true);
            return;
        }

        // If the time is right and the fetch is ok, go ahead and reload the application
        if (new Date().getDate() === 1) {
            resetPopularSearch();
        }
        window.location.reload();
    };

    const doTestFetch = async () => {
        const document = gql`
            query getLangaugeCode($id: String!) {
                libraryMeetingPlace(id: $id) {
                    primaryLanguage {
                        languageCode
                    }
                }
            }
        `;

        try {
            const response = await client.request<FetchLocaleData>({
                document,
                variables: {
                    id: meetingPlaceId,
                },
            });

            return response?.libraryMeetingPlace.primaryLanguage ? true : false;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    return offline ? (
        <div className="fixed left-4 top-4 rounded bg-white/30 p-2">
            <IoCloudOffline size="3rem" fill="#111" />
        </div>
    ) : null;
};
