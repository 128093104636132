import { useShops } from '../../../hooks/use-shops';
import { Essential } from '../../../types/visiomap';
import { List } from '../list-components/list';

type Props = {
    visioWebEssential: Essential | null;
    onClick: (id?: string) => void;
};

export const FoodDrink = ({ visioWebEssential, onClick }: Props) => {
    const { getFoodDrinkList } = useShops(visioWebEssential);

    return <List items={getFoodDrinkList()} onClick={onClick} />;
};
