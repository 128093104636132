import { Link, useParams } from 'react-router-dom';
import { useOffers, TypeOfOffer } from '../../hooks/use-offers';
import { useContext, useMemo } from 'react';
import { Offer } from './offer';
import { CmsText } from '../cms-text';
import { useLocalization } from '../../localization/use-localization';
import { Layout } from '../layout/layout';
import clsx from 'clsx';

import { getRichTextRendererOptions } from '../../helpers/rich-text-renderer-options';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';

export const SingleOffer = () => {
    const { id } = useParams();
    const { settings, locale, libraryUrlSubdirectoryCollection, domain } = useContext(MeetingPlaceContext);
    const { getOfferById, getRelatedOffers, getTenantForOffer, formatTypeOfOffer } = useOffers();
    const offer = getOfferById(id);
    const place = getTenantForOffer(offer);
    const { formatDate, getTranslation } = useLocalization();
    const related = getRelatedOffers(offer);
    const offerType = formatTypeOfOffer(offer?.typeOfOffer?.internalName || '');
    const placeLink = useMemo(() => {
        const floorPlanId = place?.floorPlanIDs?.length ? place.floorPlanIDs[0] : null;
        return floorPlanId ? `/map?selectedplace=${floorPlanId}` : '';
    }, [place]);

    if (!offer) {
        return <div>404</div>;
    }

    const contentfulRendererOptions = getRichTextRendererOptions(
        offer.body.links,
        settings.settings,
        locale || 'en',
        libraryUrlSubdirectoryCollection.items,
        domain
    );

    return (
        <Layout data-component="single-offer" showBackArrow>
            <div className="grid grid-cols-2 gap-8">
                <div>{offer.coverImage?.url ? <img className="rounded" src={offer.coverImage.url} /> : <Offer offer={offer} />}</div>
                <div>
                    <div className="relative rounded bg-beige px-12 py-20">
                        <h1 className="text-2xl font-bold">{offer.name}</h1>
                        <div
                            className={clsx('mb-4 mt-2 font-bold', {
                                'text-ikea-family-offers': offerType === TypeOfOffer.ikeaFamilyMemberOffer,
                                'text-special-offers': offerType === TypeOfOffer.specialOffer,
                            })}
                        >
                            {offerType !== TypeOfOffer.regularOffer && offer.typeOfOffer.name}
                        </div>
                        <CmsText json={offer.body.json} rendererOptions={contentfulRendererOptions} />
                        <div className="mt-4">
                            {formatDate(offer.validFrom)} - {formatDate(offer.validTo)}
                        </div>
                        {placeLink ? (
                            <div className="pt-8">
                                <Link to={placeLink} className="underline">
                                    {getTranslation('Show on map')}
                                </Link>
                            </div>
                        ) : null}
                    </div>

                    <div className="items-stretch gap-4  pt-8 2xl:col-start-2 2xl:grid 2xl:auto-rows-[1fr] 2xl:grid-cols-2">
                        {related.map((relatedOffer, index) => (
                            <Offer key={index} offer={relatedOffer} classNames="mb-8 flex flex-col xl:mb-0" />
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
