import { Essential, Floor } from '../types/visiomap';

export const singleFloor = (visioWebEssential: Essential | null) => {
    try {
        const floorCount =
            visioWebEssential!.venue!.layout!.buildings[visioWebEssential!.venue!.layout!.defaultBuildingIndex].floors.length;
        return floorCount === 1;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getGroundFloorId = (visioWebEssential: Essential | null) => {
    const groundFloorId = getGroundFloor(visioWebEssential);
    return groundFloorId?.id || '';
};

/**
 * Will explicitly return null instead of undefined if no ground floor is found. Just to avoid confusion.
 */
export const getGroundFloor = (visioWebEssential: Essential | null): Floor | null => {
    try {
        if (!visioWebEssential?.venue?.layout?.buildings) {
            throw new Error('No buildings found');
        }

        const groundFloorIndex =
            visioWebEssential.venue.layout.buildings[visioWebEssential.venue.layout.defaultBuildingIndex].groundFloorIndex;
        return (
            visioWebEssential.venue.layout.buildings[visioWebEssential.venue.layout.defaultBuildingIndex].floors[groundFloorIndex] || null
        );
    } catch (error) {
        console.error(error);
    }

    return null;
};
