import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { richTextRendererOptions } from '../helpers/rich-text-renderer-options';

type Props = {
    json?: Document | null;
    rendererOptions?: Options;
};

export const CmsText = ({ json, rendererOptions = richTextRendererOptions }: Props) =>
    json ? <div className="prose max-w-none">{documentToReactComponents(json, rendererOptions)}</div> : null;
