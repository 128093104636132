import clsx, { ClassValue } from 'clsx';
import { PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import { MapLinkSettings } from '../map-link-settings';
import { ArrowRight } from '../../assets/svg/arrow-right';

type Props = {
    showBackArrow?: boolean;
    showMapLink?: boolean;
    showMapButton?: boolean;
    className?: ClassValue | ClassValue[];
};

export const Layout: React.FC<PropsWithChildren<Props>> = ({
    showBackArrow = false,
    showMapLink = false,
    showMapButton = true,
    className = '',
    children,
}) => {
    const navigate = useNavigate();
    const { verticalMode } = useContext(MeetingPlaceContext);

    return (
        <div
            data-component="grid-listing"
            className={clsx('standard-grid bg-cover bg-no-repeat', className, {
                'min-h-screen': !verticalMode,
            })}
        >
            <div className="relative text-right">
                {showBackArrow ? (
                    <button className="absolute top-44 -ml-16 w-16 rotate-180" onClick={() => navigate(-1)} data-tracking="back-btn">
                        <ArrowRight />
                    </button>
                ) : null}
            </div>
            <div
                className={clsx(
                    'scrollbar-hide relative col-start-2 overflow-scroll pb-12 pt-24',
                    verticalMode ? 'max-h-[1200px]' : 'max-h-screen'
                )}
            >
                {children}
            </div>
            {showMapLink ? <MapLinkSettings showMapButton={showMapButton} /> : null}
        </div>
    );
};
