import { VISIO_IMAGE_PATH } from '../../visioweb/visiomap';

export const RightSidePanelKioskSelect: React.FC = () => {
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?kioskId=kiosk001`;

    return (
        <div className="grid h-full place-content-center p-8 pt-16 text-center">
            <div className="space-y-8">
                <div className="flex justify-center">
                    <img src={`${VISIO_IMAGE_PATH}marker_position.png`} />
                </div>
                <h3 className="text-3xl leading-[1.3]">Navigate to a pin and tap on it to select that kiosk as this unit's location</h3>
                <p className="text-xl leading-relaxed">
                    You can also select a kiosk via the url:
                    <br />
                    For example
                </p>
                <p className="block rounded bg-slate-200 p-2 font-bold">{url}</p>
            </div>
        </div>
    );
};
