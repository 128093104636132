import { LangType } from '../contexts/meeting-place-provider';
import { ShopItem } from '../hooks/use-shops';
import { FoodDrinkType } from '../services/meeting-place/food-drink';
import { ServiceType } from '../services/meeting-place/services';
import { ShopType } from '../services/meeting-place/shops';
import { Essential } from '../types/visiomap';

export function getShopName(shop: ShopType | ServiceType) {
    if (shop?.name) {
        return shop.name.replace(/^\s+|\s+$/g, '');
    }

    if ('brand' in shop) {
        return shop.brand?.name?.replace(/^\s+|\s+$/g, '');
    }

    return null;
}

export function getPlaceFromMap(visioWebEssential: Essential, id?: string | null) {
    try {
        return id ? visioWebEssential?.content?.getPlace({ id }) : null;
    } catch (error) {
        return null;
    }
}

export const getAnchorLink = (title: string) => title.toLocaleLowerCase().replace(/\/s+/gi, '');

export const getPlacesForItem = (
    visioWebEssential: Essential,
    placesForItem: ShopType[] | FoodDrinkType[],
    locale: LangType | null = 'en'
) => {
    try {
        const allPlaces: Set<ShopItem> = new Set();

        placesForItem.forEach(place => {
            const currentShop: ShopItem = {
                name: getShopName(place) || '',
                imagePath: place?.logo?.url || place?.brand?.logo?.url || '',
                place: null,
            };

            if (!Array.isArray(place.floorPlanIDs)) {
                return;
            }

            place.floorPlanIDs
                .map(floorPlanId => getPlaceFromMap(visioWebEssential, floorPlanId))
                .filter(p => p)
                .forEach(place => {
                    allPlaces.add({
                        ...currentShop,
                        place,
                    });
                });
        });

        return [...allPlaces]
            .filter(({ place }) => place)
            .sort((a, b) => {
                const nameToUse = a.name || a.place?.name || '';
                const nameToCompare = b.name || b.place?.name || '';
                return nameToUse.localeCompare(nameToCompare, locale as string);
            });
    } catch (error) {
        console.error('Error in getPlacesForItem', error);
    }
    return [];
};

export const getPlacesForService = (visioWebEssential: Essential, placesForItem: ServiceType[], locale: LangType | null = 'en') => {
    try {
        const allPlaces: Set<ShopItem> = new Set();

        placesForItem.forEach(place => {
            const currentShop: ShopItem = {
                name: place.name,
                imagePath: place.serviceType?.floorPlanIcon?.url,
                place: null,
            };

            if (!Array.isArray(place.floorPlanIDs)) {
                return;
            }

            place.floorPlanIDs
                .map(floorPlanId => getPlaceFromMap(visioWebEssential, floorPlanId))
                .filter(p => p)
                .forEach(place => {
                    allPlaces.add({
                        ...currentShop,
                        place,
                    });
                });
        });

        return [...allPlaces]
            .filter(({ place }) => place)
            .sort((a, b) => {
                const nameToUse = a.name || a.place?.name || '';
                const nameToCompare = b.name || b.place?.name || '';
                return nameToUse.localeCompare(nameToCompare, locale as string);
            });
    } catch (error) {
        console.error('Error in getPlacesForService', error);
    }
    return [];
};
