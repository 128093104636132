import React, { useContext } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import { NavLink } from 'react-router-dom';
import { useLocalization } from '../../localization/use-localization';
import { ArrowRight } from '../../assets/svg/arrow-right';
import { useTracking } from '../../hooks/use-tracking';
import MapCTASection from './map-cta-section';
import OffersEventsSection from './offers-events-section';
import clsx from 'clsx';
import { useEvents } from '../../hooks/use-events';
import { useOffers } from '../../hooks/use-offers';

export const Landing: React.FC = () => {
    const { dataLoaded, settings, kioskId } = useContext(MeetingPlaceContext);
    const { getTranslation } = useLocalization();
    const { getActiveEvents } = useEvents();
    const { getActiveOffers } = useOffers();
    const activeOffers = getActiveOffers();
    const activeEvents = getActiveEvents();
    const { trackSessionStart } = useTracking();
    const showEvents = settings.settings?.wayfinderSettings?.showEvents;
    const showOffers = settings.settings?.wayfinderSettings?.showOffers;

    if (!dataLoaded) {
        return <div className="w-full bg-slate-100 p-16 text-2xl font-bold">Loading. Please wait...</div>;
    }

    const renderContent = () => {
        if (
            settings?.settings?.wayfinderSettings?.loadingScreen?.url &&
            /\.mp4$/.test(settings?.settings?.wayfinderSettings?.loadingScreen?.url)
        ) {
            return (
                <div className="flex h-full max-h-screen items-center justify-center overflow-hidden">
                    <video autoPlay loop muted className="w-screen">
                        <source src={settings.settings?.wayfinderSettings.loadingScreen.url} type="video/mp4" />
                    </video>
                </div>
            );
        }

        if (
            settings?.settings?.wayfinderSettings?.loadingScreen?.url &&
            /\.(jpg|png|gif)$/.test(settings?.settings?.wayfinderSettings?.loadingScreen?.url)
        ) {
            return (
                <div
                    className="h-full overflow-hidden bg-cover"
                    style={{ backgroundImage: `url(${settings.settings?.wayfinderSettings.loadingScreen.url})` }}
                />
            );
        }

        return <div className="bg-dynamic-stroke bg-cover bg-no-repeat" />;
    };

    if ((showOffers && activeOffers.length) || (showEvents && activeEvents.length)) {
        return (
            <div className="h-full max-h-screen content-center justify-center px-[10%] py-[6%]">
                <div className="box-border flex h-full flex-row justify-between gap-8">
                    <div className={'h-full flex-[1]'}>
                        <OffersEventsSection offers={activeOffers} events={activeEvents} showEvents={showEvents} showOffers={showOffers} />
                    </div>
                    <div
                        className={clsx('h-full flex-[1] overflow-hidden rounded', {
                            'flex-[3]':
                                (!(showOffers && activeOffers.length > 0) && activeEvents.length > 0 && showEvents) ||
                                (showOffers && activeOffers.length > 0 && !(activeEvents.length > 0 && showEvents)),
                        })}
                    >
                        <MapCTASection
                            isPolygon={
                                (!(showOffers && activeOffers.length > 0) && activeEvents.length > 0 && showEvents) ||
                                (showOffers && activeOffers.length > 0 && !(activeEvents.length > 0 && showEvents))
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <NavLink
                to={kioskId ? '/map' : '/select-kiosk'}
                className="relative h-full"
                onClick={() => {
                    if (kioskId) {
                        trackSessionStart();
                    }
                }}
                data-tracking="open-map-img"
            >
                {renderContent()}
            </NavLink>

            <NavLink
                to={kioskId ? '/map' : '/select-kiosk'}
                className="absolute bottom-24 right-24 flex items-center gap-2 rounded-[140px] bg-accent px-24 py-10 font-sans text-6xl shadow-map-button"
                onClick={() => {
                    if (kioskId) {
                        trackSessionStart();
                    }
                }}
                data-tracking="open-map-btn"
            >
                {getTranslation('Map')}
                <div className="w-16">
                    <ArrowRight />
                </div>
            </NavLink>
        </>
    );
};
