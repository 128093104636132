import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { QrCode } from '../components/qr-code/qr-code';
import { ContentfulLinksType } from '../services/meeting-place/occasions';
import { WayfinderSettingsData } from '../services/meeting-place/wayfinder';
import { LangType } from '../contexts/meeting-place-provider';
import { LibraryUrlSubdirectoryItem } from '../services/meeting-place/library-url-subdirectory-collection';

export const richTextRendererOptions: Options = {
    renderNode: {
        [INLINES.HYPERLINK]: (data, children) => <QrCode uri={data?.data?.uri} text={children} />,
        [INLINES.ENTRY_HYPERLINK]: (data, children) => <QrCode uri={data?.data?.uri} text={children} />,
        [INLINES.ASSET_HYPERLINK]: (_, children) => <span>{children}</span>,
        [INLINES.RESOURCE_HYPERLINK]: (_, children) => <span>{children}</span>,
    },
    renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
            // If it's not the first segment, add a line break
            if (index > 0) {
                children.push(<br key={index} />);
            }

            // Add the text segment to the children array
            children.push(textSegment);

            return children;
        }, [] as React.ReactNode[]);
    },
};

export const getRichTextRendererOptions = (
    links: ContentfulLinksType,
    settings: WayfinderSettingsData | null,
    locale: LangType = 'en',
    libraryUrlSubdirectoryCollection: LibraryUrlSubdirectoryItem[],
    domain: string
): Options => {
    const getUrlForEntity = (entity: string, slug: string) => {
        if (!settings || !settings?.socialMediaLinks?.webUrl) {
            return '';
        }

        let entitySlug = slug.replace(/^\/|\/$/, '');
        switch (entity) {
            case 'MeetingPlacePageEvent':
                entitySlug = settings?.eventListPage?.slug ? `${settings.eventListPage.slug.replace(/^\/|\/$/g, '')}/${entitySlug}` : '';
                break;
            case 'MeetingPlacePageOffer':
                entitySlug = settings?.offerListPage?.slug ? `${settings?.offerListPage?.slug?.replace(/^\/|\/$/g, '')}/${entitySlug}` : '';
                break;
        }

        return `${settings.socialMediaLinks.webUrl.replace(/\/$/, '')}/${locale}/${entitySlug}`;
    };

    return {
        ...richTextRendererOptions,
        renderNode: {
            ...richTextRendererOptions.renderNode,
            [BLOCKS.EMBEDDED_ASSET]: data => {
                const id = data.data.target?.sys?.id;
                if (!id) {
                    return null;
                }

                const entry = links?.assets?.block?.find(block => block?.sys?.id === id);
                if (!entry) {
                    return null;
                }

                return <img className="rounded" src={entry.url ?? ''} alt={entry.title ?? ''} />;
            },
            [INLINES.ASSET_HYPERLINK]: (data, children) => {
                const id = data.data.target?.sys?.id;
                if (!id) {
                    return null;
                }

                const entry = links?.assets?.hyperlink?.find(block => block?.sys?.id === id);
                if (!entry) {
                    return null;
                }

                return <QrCode uri={entry.url ?? ''} text={children} />;
            },
            [BLOCKS.EMBEDDED_ENTRY]: data => {
                // We currently only support external links here in the wayfinder
                const id = data.data.target?.sys?.id;
                if (!id) {
                    return null;
                }

                const entry = links?.entries?.block?.find(block => block?.sys?.id === id);
                if (!entry) {
                    return null;
                }

                let uri = entry?.externalLink ?? '';

                if (!uri && domain) {
                    libraryUrlSubdirectoryCollection.forEach(el => {
                        if (entry && entry.internalLink && el.contentTypes[0].indexOf(entry.internalLink.__typename) != -1) {
                            uri = `${domain}/${locale}/${el.urlSubdirectory}/${entry.internalLink.slug}`;
                        }
                    });
                }

                return <QrCode isButton uri={uri} text={entry?.linkText ?? ''} />;
            },
            [INLINES.ENTRY_HYPERLINK]: (data, children) => {
                const id = data.data.target?.sys?.id;
                if (!id) {
                    return null;
                }

                const entry = links?.entries?.hyperlink?.find(block => block?.sys?.id === id);
                if (!entry) {
                    return null;
                }

                return <QrCode uri={getUrlForEntity(entry.__typename, entry?.slug)} text={children} />;
            },
        },
    };
};
