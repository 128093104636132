import React, { useContext } from 'react';
import clsx from 'clsx';
import { useLocalization } from '../../../localization/use-localization';
import { LangType, MeetingPlaceContext } from '../../../contexts/meeting-place-provider';
import { CheckMark } from '../../../assets/svg/checkmark';

export const LanguagePicker: React.FC = () => {
    const { setLang, locale, lang } = useContext(MeetingPlaceContext);
    const { getLanguageName } = useLocalization();

    const handleLangSelection = (selectedLang: LangType) => {
        setLang(selectedLang);
    };

    return (
        <div className="mb-4 flex flex-col items-end gap-6">
            <button
                role="button"
                className={clsx('flex items-center', {
                    'font-semibold': lang === locale,
                })}
                onClick={() => handleLangSelection(locale!)}
                data-tracking="pick-lang-btn"
            >
                {getLanguageName(locale!)} {lang === locale ? <CheckMark className="w-[1.5em]" /> : ''}
            </button>

            <button
                role="button"
                className={clsx('flex items-center', {
                    'font-semibold': lang === 'en',
                })}
                onClick={() => handleLangSelection('en')}
                data-tracking="pick-lang-btn"
            >
                {getLanguageName('en')} {lang === 'en' ? <CheckMark className="w-[1.5em]" /> : ''}
            </button>
        </div>
    );
};
