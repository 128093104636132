import { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { RouterType } from './main';
import { useLocalization } from './localization/use-localization';
import { LangType, MeetingPlaceContext } from './contexts/meeting-place-provider';

type Props = {
    router: RouterType;
};

const APPLICATION_RESET_TIMER = window.location.host.includes('localhost') ? 600 * 1000 : 30 * 1000;
const STEP_TIMER = 1.5 * 1000;

export const Reset = ({ router }: Props) => {
    const { getTranslation } = useLocalization();
    const { setLang, locale } = useContext(MeetingPlaceContext);
    const resetTimeout = useRef<NodeJS.Timeout | null>(null);
    const countDownTimeout = useRef<NodeJS.Timeout | null>(null);
    const [countDown, setCountDown] = useState<number | null>(null);

    const onDocumentInteract = () => {
        clearCountDown();
        if (resetTimeout?.current) {
            clearTimeout(resetTimeout.current);
        }

        if (router.state.location.pathname === '/') {
            setCountDown(null);
            return;
        }

        resetTimeout.current = setTimeout(() => {
            setCountDown(5);
        }, APPLICATION_RESET_TIMER);
    };

    useEffect(() => {
        if (countDown === null) {
            return;
        }

        if (router.state.location.pathname === '/') {
            clearCountDown();
            return;
        }

        if (countDown === 0) {
            setCountDown(null);
            setLang(locale as LangType);
            router.navigate('/');
            return;
        }

        countDownTimeout.current = setTimeout(() => {
            setCountDown(countDown - 1);
        }, STEP_TIMER);
    }, [countDown, router, locale, setLang]);

    const clearCountDown = () => {
        setCountDown(null);

        if (countDownTimeout?.current) {
            clearTimeout(countDownTimeout.current);
        }
    };

    useEffect(() => {
        document.addEventListener('click', onDocumentInteract);
        document.addEventListener('drag', onDocumentInteract);

        return () => {
            document.removeEventListener('click', onDocumentInteract);
            document.addEventListener('drag', onDocumentInteract);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (countDown === null) {
        return null;
    }

    return (
        <div
            className={clsx('fixed inset-0 z-50 grid place-items-center transition-colors duration-[6000ms] ease-in', {
                'bg-black/10': countDown === 5,
                'bg-black/90': countDown < 5,
            })}
        >
            <button className="grid h-[32rem] w-[32rem] place-items-center rounded-full bg-accent px-8 font-sans font-bold">
                <div className="text-center">
                    <div className="pb-4 text-[7.5rem] leading-none">{countDown}</div>
                    <div className="text-5xl leading-[1.25]">{getTranslation('Touch me to continue!')}</div>
                </div>
            </button>
        </div>
    );
};
