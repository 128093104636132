import { useContext } from 'react';
import { MeetingPlaceContext } from '../../contexts/meeting-place-provider';
import { Layout } from '../layout/layout';

export const Feedback = () => {
    const { footer } = useContext(MeetingPlaceContext);

    return (
        <Layout data-component="feedback" showMapLink>
            {footer.items.map((item, index) =>
                item.footer?.feedbackUrlWayfinder ? (
                    <div key={index} className="grid h-full w-full place-items-center">
                        <iframe width="100%" height={500} src={item.footer?.feedbackUrlWayfinder} />
                    </div>
                ) : null
            )}
        </Layout>
    );
};
