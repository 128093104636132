import React from 'react';
import { useLocalization } from '../../../localization/use-localization';
import { OpeningHoursExceptionsCollectionItem, ShopType } from '../../../services/meeting-place/shops';

const HOURS_PER_DAY = 30;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_MINUTE = 60;
const MILLISECONDS_PER_SECOND = 1000;
const THIRTY_DAY_COUNT = 30;

type OpeningHoursProps = {
    activeShop: ShopType;
    parentStyling?: string;
};

export const OpeningHoursException: React.FC<OpeningHoursProps> = ({ activeShop, parentStyling }) => {
    const { getTranslation } = useLocalization();
    const { openingHoursExceptionsCollection } = activeShop;
    const openingHoursExceptionsDisplay: {
        [key: string]: {
            startDate: number;
            endDate: number | null;
            startMonth: number;
            endMonth: number | null;
            startYear: number;
            endYear: number | null;
            opens: string;
            closes: string;
            name: string;
        };
    } = {};

    if (openingHoursExceptionsCollection && openingHoursExceptionsCollection.items.length > 0) {
        openingHoursExceptionsCollection.items.sort(
            (i1: OpeningHoursExceptionsCollectionItem, i2: OpeningHoursExceptionsCollectionItem) => {
                if (i1 && i1.date && i2 && i2.date) {
                    const openingHoursExceptionsDate1 = new Date(i1.date);
                    const openingHoursExceptionsDateMs1 = openingHoursExceptionsDate1.getTime();
                    const openingHoursExceptionsDate2 = new Date(i2.date);
                    const openingHoursExceptionsDateMs2 = openingHoursExceptionsDate2.getTime();
                    return openingHoursExceptionsDateMs2 > openingHoursExceptionsDateMs1 ? -1 : 1;
                }
                return 0;
            }
        );

        for (const item of openingHoursExceptionsCollection.items) {
            if (item && item.name && item.date && item.opens && item.closes) {
                const now = new Date();
                const nowInMilliseconds = now.getTime();
                const thirtyDaysFromNow =
                    nowInMilliseconds + THIRTY_DAY_COUNT * HOURS_PER_DAY * MINUTES_PER_HOUR * SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND;

                const openingHoursExceptionsDate = new Date(item.date);
                const openingHoursExceptionsDateMs = openingHoursExceptionsDate.getTime();
                if (openingHoursExceptionsDateMs < thirtyDaysFromNow && openingHoursExceptionsDateMs > nowInMilliseconds) {
                    if (openingHoursExceptionsDisplay[item.name]) {
                        const date = new Date(item.date).getDate();
                        const month = new Date(item.date).getMonth();
                        const year = new Date(item.date).getFullYear();

                        if (!openingHoursExceptionsDisplay[item.name].endDate) {
                            if (openingHoursExceptionsDisplay[item.name].startDate > date) {
                                openingHoursExceptionsDisplay[item.name].endDate = openingHoursExceptionsDisplay[item.name].startDate;
                                openingHoursExceptionsDisplay[item.name].startDate = date;
                            } else if (openingHoursExceptionsDisplay[item.name].startDate < date) {
                                openingHoursExceptionsDisplay[item.name].endDate = date;
                            }
                        }

                        if (openingHoursExceptionsDisplay[item.name].startMonth > month) {
                            openingHoursExceptionsDisplay[item.name].endMonth = openingHoursExceptionsDisplay[item.name].startMonth;
                            openingHoursExceptionsDisplay[item.name].startMonth = month;
                            openingHoursExceptionsDisplay[item.name].endYear = openingHoursExceptionsDisplay[item.name].startYear;
                            openingHoursExceptionsDisplay[item.name].startYear = year;
                        } else if (openingHoursExceptionsDisplay[item.name].startMonth < month) {
                            openingHoursExceptionsDisplay[item.name].endMonth = month;
                            openingHoursExceptionsDisplay[item.name].endYear = year;
                        }
                    } else {
                        openingHoursExceptionsDisplay[item.name] = {
                            startDate: new Date(item.date).getDate(),
                            endDate: null,
                            startMonth: new Date(item.date).getMonth(),
                            endMonth: null,
                            startYear: new Date(item.date).getFullYear(),
                            endYear: null,
                            name: item.name,
                            opens: item.opens,
                            closes: item.closes,
                        };
                    }
                }
            }
        }
        if (Object.keys(openingHoursExceptionsDisplay).length) {
            return (
                <div className={parentStyling || ''}>
                    <h2 className="text-xl font-semibold">{getTranslation('Opening hours exception')}</h2>
                    {Object.keys(openingHoursExceptionsDisplay).map((key, i) => {
                        const el = openingHoursExceptionsDisplay[key];
                        const dateString = el.endDate
                            ? el.endMonth
                                ? `${el.startDate}.${el.startMonth + 1}.${el.startYear} - ${el.endDate}.${el.endMonth + 1}.${el.endYear}`
                                : `${el.startDate} - ${el.endDate}.${el.startMonth + 1}.${el.startYear}`
                            : `${el.startDate}.${el.startMonth + 1}.${el.startYear}`;

                        return (
                            <p key={i}>
                                <span className="font-medium">{`${key}, ${dateString} : ${el.opens} - ${el.closes}`}</span>
                            </p>
                        );
                    })}
                </div>
            );
        } else {
            return <div />;
        }
    } else {
        return <div />;
    }
};
