/**
 * Tries to get a parameter from the url and if that's not possible, try fallback method of getting it from the root element in DOM.
 * @key key
 * @returns string if found, null if not found
 */
export const getParamFromUrlOrDOM = (paramKey: string) => {
    let paramValue = null;

    const urlParam = new URLSearchParams(window.location.search).get(paramKey);

    if (urlParam) {
        paramValue = urlParam;
    } else {
        const root = document.getElementById('root');
        if (root) {
            const rootParam = root.getAttribute('data-' + paramKey) || root.getAttribute(paramKey);
            if (rootParam) {
                paramValue = rootParam;
            }
        }
    }

    // Use Wola Park as a fallback for netlify stage
    if (paramKey === 'tagId' && window?.location?.host.includes('.netlify.app') && paramValue === 'contentfulTagIDPlaceholder') {
        return 'meetingPlaceWola';
    }

    return paramValue;
};
